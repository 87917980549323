import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

import "./SMSTimerModal.css";
import { useEffect, useState } from "react";

export const SMSTimerModal = ({ openModalSMSTimer, setOpenModalSMSTimer }) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(300);

    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer((prev) => {
                if (prev === 0) {
                    clearInterval(interval);
                    setOpenModalSMSTimer(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const getFormattedTime = () => {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    useEffect(() => {
        if (openModalSMSTimer) {
            startTimer();
        }
    }, [openModalSMSTimer]);

    return (
        <Modal
            title={t('wizard.verification')}
            footer={null}
            open={openModalSMSTimer}
            closable={true}
            onCancel={() => setOpenModalSMSTimer(false)}
        >
            <div className="modal-container">
                <p className="nu-input-label">{t('wizard.sms_delay')} </p>
                <div className="countdown-container">{getFormattedTime()}</div>
                <Row className="action-buttons mobile-button">
                    <Col md={3} xs={1} />
                    <Col md={6} xs={10}>
                        <button type="button" className="btn btn-primary btn-active" onClick={() => setOpenModalSMSTimer(false)}>
                            <span className="cancel-text">{t('general.confirm')}</span>
                            <CheckIcon />
                        </button>
                    </Col>
                    <Col md={3} xs={1} />
                </Row>
            </div>
        </Modal>
    );
};