import { useEffect, useRef, useState } from "react";
import "./QRLoader.css";
import EmailSignatureService from "../../services/EmailSignatureService";
import { Loader } from "../Loader/Loader";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QR_EXPIRATION_TIME } from "../../constants/GeneralConstants";
import { useNavigate } from "react-router-dom";

export const QRLoader = ({ signerGuid, setReceivedGraphData, additionalSignature }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingQRImage, setLoadingQR] = useState(false);
  const [qrData, setQRData] = useState({});
  const [showQR, setShowQR] = useState(true);
  const { qr64Data, qrToken } = qrData;
  const pollingActiveRef = useRef(false);
  const qrExpirationRef = useRef(null);
  const abortControllerRef = useRef(null);
  let isPollingActive = true;

  useEffect(() => {
    getQRData();
    return () => {
      stopPolling();
      clearTimeout(qrExpirationRef.current);
    };
  }, []);

  useEffect(() => {
    if (showQR && qrToken) {
      startPolling(qrToken);
      startTimer();
    }
    if (!showQR) {
      stopPolling();
    }
  }, [showQR, qrToken]);

  const getQRData = () => {
    setLoadingQR(true);
    const body = { SignersGuid: [signerGuid] };

    EmailSignatureService.getQRData(body)
      .then((response) => {
        const { QRCodeImage, Token } = response.data;
        setQRData({ qr64Data: QRCodeImage, qrToken: Token });
        setShowQR(true);
        isPollingActive = true;
      })
      .catch(e => {
        const error = e.response?.data;
        if (error?.toLowerCase().includes('invalid authentication')) {
          navigate('/expired-personal-code');
        }
      })
      .finally(() => setLoadingQR(false));
  };

  const startPolling = async (token) => {
    if (!token || pollingActiveRef.current) return;
    pollingActiveRef.current = true;
    abortControllerRef.current = new AbortController();
    await executePolling(token, abortControllerRef.current.signal);
  };


  const executePolling = async (token, signal) => {
    while (isPollingActive) {
      try {
        if (signal.aborted) {
          console.log("Polling aborted by signal");
          break;
        }

        const response = await EmailSignatureService.pollingQR(token, signal);
        const { SignatureImage, SignatureRawData } = response.data;
        setReceivedGraphData({ SignatureImage, SignatureRawData });

      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Polling aborted");
          break;
        }
        console.error("Polling error:", error.response ? error.response.data : error.message);
      }
    }
    pollingActiveRef.current = false;
  };


  const startTimer = () => {
    setTimeout(() => {
      setShowQR(false);
      isPollingActive = false;
      stopPolling();
    }, QR_EXPIRATION_TIME);
  };

  const stopPolling = () => {
    if (pollingActiveRef.current) {
      pollingActiveRef.current = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  };

  const resetQR = () => {
    stopPolling();
    setShowQR(true);
    isPollingActive = true;
    setQRData({});
    getQRData();
  };

  return (
    <>
      {loadingQRImage ? (
        <Loader />
      ) : (
        <Container className={additionalSignature ? "qr-container-reverse" : "qr-container"}>
          {showQR ? (
            <>
              <img
                src={`data:image/png;base64,${qr64Data}`}
                className="qr-image"
                alt="QR Code"
              />
              <p className="qr-instructions-title">{t("sign.better_experience")}</p>
              <p>{t("sign.you_can_scan")}</p>
            </>
          ) : (
            <span className="qr-image" style={{ marginTop: "15px" }}>
              {t("sign.qr.update_part_1")} {" "}
              <a href="#" onClick={(e) => { e.preventDefault(); resetQR(); }}>
                {t("sign.qr.update_part_2")}
              </a>{" "}
              {t("sign.qr.update_part_3")}
            </span>
          )}
        </Container>
      )}
    </>
  );
};

