const DocumentMapService = {};

//Map
DocumentMapService.resetMap = () => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) {
    console.log("No map found to reset. The map is already cleared.");
    return;
  }
  localStorage.removeItem("DOCUMENT_MAP_SERVICE");
};

DocumentMapService.initializeMap = (totalPages) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) {
    const map = Array(totalPages).fill(-1);
    localStorage.setItem("DOCUMENT_MAP_SERVICE", JSON.stringify(map));
  }
};

DocumentMapService.isPageLoaded = (pageNumber) => {
  return DocumentMapService.getPagePositionByPageNumber(pageNumber) !== -1;
}

DocumentMapService.updateMap = (startPageNumberToUpdate, pagesCountToUpdate, startPagePosition) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) return;
  const map = JSON.parse(existingMap);
  const startPageIndex = startPageNumberToUpdate - 1;
  if (startPageIndex < 0 || startPageIndex + pagesCountToUpdate > map.length) return;

  for (let i = 0; i < pagesCountToUpdate; i++) {
    map[startPageIndex + i] = startPagePosition + i;
  }

  let newPosition = startPagePosition + pagesCountToUpdate;
  for (let i = startPageIndex + pagesCountToUpdate; i < map.length; i++) {
    if (map[i] !== -1) map[i] = newPosition++;
  }

  localStorage.setItem("DOCUMENT_MAP_SERVICE", JSON.stringify(map));
};

DocumentMapService.updatePagesList = (currentList, newPages, newIndexPosition) => {
  currentList = currentList.filter(item => !newPages.includes(item));
  currentList.splice(newIndexPosition, 0, ...newPages);

  return currentList;
};

//From position to page number
DocumentMapService.getPageNumberByPagePosition = (pagePosition) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) return null;
  const map = JSON.parse(existingMap);
  const index = map.indexOf(pagePosition);
  return index !== -1 ? index + 1 : false;
};

//From page number to position
DocumentMapService.getPagePositionByPageNumber = (pageNumber) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) return null;
  const map = JSON.parse(existingMap);
  return pageNumber >= 1 && pageNumber <= map.length ? map[pageNumber - 1] : null;
};

//From scroll to page number

DocumentMapService.getPageNumberByScroll = (scrollId, totalPagesLoaded) => {
  // getDocumentPageHeight
  const scrollContainer = document.getElementById(scrollId);

  const scrollTop = scrollContainer.scrollTop;
  const pageSize = scrollContainer.scrollHeight / totalPagesLoaded;
  const pagePosition = Math.floor(scrollTop / pageSize);

  return DocumentMapService.getPageNumberByPagePosition(pagePosition);
};

//From page number to scroll
DocumentMapService.determinePageScrollPosition = (pageNumber, totalPagesLoaded, documentPageHeight) => {
  return (documentPageHeight / totalPagesLoaded) * pageNumber;
};

DocumentMapService.setScrollByPageNumber = (scrollRef, pageNumber, totalPagesLoaded, scrollFlag) => {
  scrollFlag.current = true;
  const pageSize = scrollRef.current.scrollHeight / totalPagesLoaded;
  const pagePosition = DocumentMapService.getPagePositionByPageNumber(pageNumber);
  scrollRef.current.scrollTop = pageSize * pagePosition;
};

DocumentMapService.determineNeighborPageNumberToFetch = (currentPage) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) return -1;
  const map = JSON.parse(existingMap);
  const currentPageIndex = map.indexOf(currentPage);
  if (currentPageIndex === -1) return -1;
  return map[currentPageIndex + 1] === -1 ? map.indexOf(map[currentPageIndex + 1]) + 2 : -1;
};

DocumentMapService.getImagesHeightsAverage = (imagesClassName) => {
  const images = document.getElementsByClassName(imagesClassName);
  const imagesArray = Array.from(images);
  let totalHeight = 0;

  imagesArray.forEach((image) => {
    totalHeight += image.offsetHeight;
  })

  return totalHeight / imagesArray.length;
}

DocumentMapService.determinePagePosition = (newPageNumber, lastPagePosition) => {
  const existingMap = localStorage.getItem("DOCUMENT_MAP_SERVICE");
  if (!existingMap) return null;
  const map = JSON.parse(existingMap);
  if (newPageNumber < 0 || newPageNumber > map.length) return null;
  for (let i = lastPagePosition; i >= 0; i--) {
    if (newPageNumber > DocumentMapService.getPageNumberByPagePosition(i)) return i + 1;
  }
  return 0;
};

export default DocumentMapService;