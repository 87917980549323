import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";
import { CancelIcon } from "../../SvgComponents/CancelIcon/CancelIcon";
import "./SupportVerificationModal.css";

export const SupportVerificationModal = ({ openSupportVerificationModal, setOpenSupportVerificationModal, handlerSupportTicket }) => {
    const { t } = useTranslation();

    const handleSupportVerificationModal = () => {
        setOpenSupportVerificationModal(false);
        handlerSupportTicket();
    }

    return (
        <Modal
            title={t("wizard.verification")}
            footer={null}
            open={openSupportVerificationModal}
            closable={true}
            onCancel={() => handleSupportVerificationModal()}
        >
            <div className="modal-container" style={{ textAlign: 'left' }}>
                <span className="custom-list"><CheckIcon secondayColor={true} /><span>{t("wizard.check_network")}</span></span><br></br>
                <span className="custom-list"><CheckIcon secondayColor={true} /><span>{t("wizard.sim_card_check")}</span></span><br></br>
                <span className="custom-list"><CheckIcon secondayColor={true} /><span>{t("wizard.restart_device")}</span></span><br></br>
                <span className="custom-list"><CheckIcon secondayColor={true} /><span>{t("wizard.inbox_check")}</span></span>
                <Row className="action-buttons mobile-button">
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-secondary btn-cancel" onClick={() => setOpenSupportVerificationModal(false)}>
                            <span className="cancel-text">{t('general.cancel')}</span>
                            <CancelIcon />
                        </button>
                    </Col>
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-primary btn-active" onClick={() => handleSupportVerificationModal()}>
                            <span className="cancel-text">{t('general.continue')}</span>
                            <CheckIcon />
                        </button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};