import Helper from "../../util/helper";

import { ATTACHMENT, CHECKBOX, MANDATORY, RADIOBUTTON, TEXTBOX, CHOICE } from '../../constants/GeneralConstants';

import { TextBox } from "../TextBox/TextBox";
import { RadioButton } from "../RadioButton/RadioButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { Attachment } from "../Attachment/Attachment";
import { Fragment, useContext } from "react";
import { FormContext } from "../../contexts/FormContext";
import { FormTitle } from "../FormTitle/FormTitle";
import { Choice } from "../Choice/Choice";

export const AdvancedFormGroup = ({ index, pagesLength }) => {
    const { formTransform, setFormTransform, getComponentOriginalPosition, setMaxPageWithQuestions, checkDisabled } = useContext(FormContext);
    const { pageData: group, maxPageWithQuestions }  = Helper.getAdvancedFormInfo(formTransform, index, pagesLength);

    setMaxPageWithQuestions(maxPageWithQuestions);

    const elements = group.components.map((component, k) => {
      const onUpdateValue = (newResponse) => {
        if (component.type === CHOICE) {
          component = component.radioButtonParentData;
        } 
        let existError = false;
        if (newResponse === undefined || newResponse === '' || newResponse?.fileinbase64 === null) {
          existError = true;
        }
        if (component.type === TEXTBOX) {
          if ((component.required.toUpperCase() === MANDATORY || !component?.optional) && newResponse === "") {
            existError = true;
          } else {
            existError = false;
          }
        }
        component.error = existError;
        component.response = newResponse;
        if (component.type === RADIOBUTTON) {
          if (component.required.toUpperCase() === MANDATORY && (newResponse === undefined || newResponse === null)) {
            existError = true;
          } else {
            component.selectedchoice = newResponse;
          }
        }
        const clonedForm = Helper.clone(formTransform);

        const { sectionIndex, groupIndex, componentIndex } = getComponentOriginalPosition(component.id);
        
        clonedForm[sectionIndex][groupIndex]["components"][componentIndex] = component;

        setFormTransform(clonedForm);
      };

      let choiceIndex = null;

      if (component.type === CHOICE) {
        choiceIndex = component.radioButtonParentData.choices.findIndex((choice) => {
          return choice.title.text === component.title.text && 
          choice.title.disableprintpdf === component.title.disableprintpdf && 
          JSON.stringify(choice.title.position) === JSON.stringify(component.title.position);
        });
      }

      return (
        <Fragment key={k}>
          {component.type === TEXTBOX && (
            <TextBox
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
          {component.type === CHECKBOX && (
              <Checkbox
                key={k}
                onUpdateValue={onUpdateValue}
                componentData={component}
                appearance="checkbox"
                isAdvancedForm
              />
            )}
          {component.type === RADIOBUTTON && (
            <RadioButton
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
          {component.type === ATTACHMENT && (
            <Attachment
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
          {component.type === CHOICE && (
            <Choice
              isAdvancedForm
              name={component.radioButtonParentData.id}
              label={component.title.disableprintpdf ? '' : component.title.text}
              key={k}
              choiceIndex={choiceIndex} 
              parentData={component.radioButtonParentData} 
              onUpdateValue={onUpdateValue}
              position={component?.title?.position}
              isDisabled={checkDisabled(component.radioButtonParentData)}
            />
          )}
        </Fragment>
      );
    })

    return <Fragment>
    {
      group.title.map((titleElement, titleIndex) => 
        <FormTitle key={titleIndex} isAdvancedForm title={titleElement}></FormTitle>
      )
    }
    {elements}
  </Fragment>;
}