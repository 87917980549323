import React, { useEffect, useState } from 'react';

export const OtpWhatsappIcon = ({ width, height, disabled }) => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    let color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    if (disabled) color = '#9C9C9C';
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      <svg width={width} height={height} viewBox="0 0 139 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M3.59571 91.6583H1.30847C1.02412 91.6583 0.751358 91.5451 0.550293 91.3435C0.349227 91.142 0.236328 90.8687 0.236328 90.5837C0.236328 90.2986 0.349227 90.0253 0.550293 89.8238C0.751358 89.6222 1.02412 89.509 1.30847 89.509H3.59571C3.88006 89.509 4.15282 89.6222 4.35389 89.8238C4.55495 90.0253 4.66785 90.2986 4.66785 90.5837C4.66785 90.8687 4.55495 91.142 4.35389 91.3435C4.15282 91.5451 3.88006 91.6583 3.59571 91.6583Z" fill={color} />
          <path d="M12.4585 91.6583H9.88537C9.60102 91.6583 9.32826 91.5451 9.1272 91.3435C8.92613 91.142 8.81323 90.8687 8.81323 90.5837C8.81323 90.2986 8.92613 90.0253 9.1272 89.8238C9.32826 89.6222 9.60102 89.509 9.88537 89.509H12.4585C12.7429 89.509 13.0156 89.6222 13.2167 89.8238C13.4178 90.0253 13.5307 90.2986 13.5307 90.5837C13.5307 90.8687 13.4178 91.142 13.2167 91.3435C13.0156 91.5451 12.7429 91.6583 12.4585 91.6583Z" fill={color} />
          <path d="M6.81213 97.3177C6.52778 97.3177 6.25502 97.2045 6.05395 97.003C5.85289 96.8015 5.73999 96.5281 5.73999 96.2431V93.9506C5.73999 93.6656 5.85289 93.3922 6.05395 93.1907C6.25502 92.9892 6.52778 92.876 6.81213 92.876C7.09648 92.876 7.36924 92.9892 7.57031 93.1907C7.77138 93.3922 7.88427 93.6656 7.88427 93.9506V96.2431C7.88427 96.5281 7.77138 96.8015 7.57031 97.003C7.36924 97.2045 7.09648 97.3177 6.81213 97.3177Z" fill={color} />
          <path d="M6.81213 88.721C6.52778 88.721 6.25502 88.6078 6.05395 88.4063C5.85289 88.2047 5.73999 87.9314 5.73999 87.6464V85.0673C5.73999 84.7823 5.85289 84.5089 6.05395 84.3074C6.25502 84.1059 6.52778 83.9927 6.81213 83.9927C7.09648 83.9927 7.36924 84.1059 7.57031 84.3074C7.77138 84.5089 7.88427 84.7823 7.88427 85.0673V87.6464C7.88427 87.9314 7.77138 88.2047 7.57031 88.4063C7.36924 88.6078 7.09648 88.721 6.81213 88.721Z" fill={color} />
        </g>
        <g opacity="0.3">
          <path d="M103.359 91.6656H101.072C100.788 91.6656 100.515 91.5524 100.314 91.3508C100.113 91.1493 100 90.876 100 90.591C100 90.306 100.113 90.0326 100.314 89.8311C100.515 89.6296 100.788 89.5164 101.072 89.5164H103.359C103.644 89.5164 103.916 89.6296 104.118 89.8311C104.319 90.0326 104.432 90.306 104.432 90.591C104.432 90.876 104.319 91.1493 104.118 91.3508C103.916 91.5524 103.644 91.6656 103.359 91.6656Z" fill={color} />
          <path d="M112.222 91.6656H109.649C109.365 91.6656 109.092 91.5524 108.891 91.3508C108.69 91.1493 108.577 90.876 108.577 90.591C108.577 90.306 108.69 90.0326 108.891 89.8311C109.092 89.6296 109.365 89.5164 109.649 89.5164H112.222C112.507 89.5164 112.779 89.6296 112.98 89.8311C113.181 90.0326 113.294 90.306 113.294 90.591C113.294 90.876 113.181 91.1493 112.98 91.3508C112.779 91.5524 112.507 91.6656 112.222 91.6656Z" fill={color} />
          <path d="M106.576 97.3252C106.291 97.3252 106.018 97.212 105.817 97.0104C105.616 96.8089 105.503 96.5356 105.503 96.2506V93.958C105.503 93.673 105.616 93.3997 105.817 93.1982C106.018 92.9966 106.291 92.8834 106.576 92.8834C106.86 92.8834 107.133 92.9966 107.334 93.1982C107.535 93.3997 107.648 93.673 107.648 93.958V96.2506C107.648 96.5356 107.535 96.8089 107.334 97.0104C107.133 97.212 106.86 97.3252 106.576 97.3252Z" fill={color} />
          <path d="M106.576 88.7283C106.291 88.7283 106.018 88.6151 105.817 88.4136C105.616 88.212 105.503 87.9387 105.503 87.6537V85.0746C105.503 84.7896 105.616 84.5163 105.817 84.3148C106.018 84.1132 106.291 84 106.576 84C106.86 84 107.133 84.1132 107.334 84.3148C107.535 84.5163 107.648 84.7896 107.648 85.0746V87.6537C107.648 87.9387 107.535 88.212 107.334 88.4136C107.133 88.6151 106.86 88.7283 106.576 88.7283Z" fill={color} />
        </g>
        <path opacity="0.3" d="M19.4705 116.446C18.7832 116.446 18.1113 116.242 17.5398 115.859C16.9683 115.476 16.523 114.932 16.2599 114.296C15.9969 113.659 15.928 112.959 16.0621 112.283C16.1962 111.607 16.5272 110.987 17.0132 110.5C17.4992 110.013 18.1185 109.681 18.7926 109.546C19.4667 109.412 20.1655 109.481 20.8005 109.745C21.4355 110.008 21.9781 110.455 22.36 111.028C22.7418 111.6 22.9457 112.274 22.9457 112.963C22.945 113.886 22.5786 114.772 21.9271 115.425C21.2755 116.078 20.3919 116.445 19.4705 116.446ZM19.4705 111.629C19.2073 111.629 18.95 111.707 18.7312 111.854C18.5123 112 18.3416 112.208 18.2409 112.452C18.1402 112.696 18.1139 112.964 18.1652 113.223C18.2166 113.482 18.3433 113.719 18.5294 113.906C18.7156 114.092 18.9527 114.22 19.2108 114.271C19.469 114.322 19.7366 114.296 19.9798 114.195C20.223 114.094 20.4309 113.923 20.5771 113.704C20.7234 113.484 20.8015 113.227 20.8015 112.963C20.8017 112.787 20.7673 112.614 20.7005 112.452C20.6337 112.29 20.5357 112.143 20.4121 112.019C20.2885 111.894 20.1418 111.796 19.9802 111.729C19.8186 111.662 19.6454 111.627 19.4705 111.627V111.629Z" fill={color} />
        <path opacity="0.3" d="M133.825 49.0509C133.138 49.0509 132.466 48.8466 131.895 48.4638C131.323 48.0811 130.878 47.5371 130.615 46.9006C130.352 46.2642 130.283 45.5638 130.417 44.8881C130.551 44.2125 130.882 43.5918 131.368 43.1047C131.854 42.6175 132.473 42.2858 133.147 42.1514C133.821 42.017 134.52 42.086 135.155 42.3496C135.79 42.6133 136.333 43.0597 136.715 43.6325C137.097 44.2053 137.3 44.8788 137.3 45.5677C137.299 46.4911 136.933 47.3764 136.281 48.0294C135.63 48.6824 134.747 49.0497 133.825 49.0509ZM133.825 44.2337C133.562 44.2337 133.305 44.312 133.086 44.4585C132.867 44.6051 132.696 44.8134 132.596 45.0572C132.495 45.3009 132.469 45.5692 132.52 45.8279C132.571 46.0867 132.698 46.3244 132.884 46.5109C133.07 46.6975 133.307 46.8245 133.566 46.876C133.824 46.9275 134.091 46.9011 134.335 46.8001C134.578 46.6991 134.786 46.5282 134.932 46.3088C135.078 46.0894 135.156 45.8315 135.156 45.5677C135.156 45.2139 135.016 44.8746 134.766 44.6243C134.517 44.374 134.178 44.233 133.825 44.2323V44.2337Z" fill={color} />
        <path opacity="0.3" d="M29.4705 43.8914C28.7832 43.8914 28.1113 43.6872 27.5398 43.3044C26.9684 42.9217 26.5229 42.3777 26.2598 41.7412C25.9968 41.1047 25.9281 40.4044 26.0622 39.7287C26.1962 39.053 26.5273 38.4324 27.0133 37.9453C27.4993 37.4581 28.1185 37.1264 28.7926 36.992C29.4667 36.8576 30.1654 36.9266 30.8004 37.1902C31.4354 37.4538 31.9782 37.9003 32.36 38.4731C32.7419 39.0459 32.9456 39.7193 32.9456 40.4082C32.9445 41.3317 32.578 42.217 31.9266 42.87C31.2751 43.523 30.3919 43.8903 29.4705 43.8914ZM29.4705 39.0743C29.2073 39.0743 28.9501 39.1525 28.7312 39.2991C28.5123 39.4457 28.3417 39.654 28.2409 39.8978C28.1402 40.1415 28.1139 40.4097 28.1653 40.6685C28.2166 40.9273 28.3434 41.1649 28.5295 41.3515C28.7156 41.5381 28.9527 41.6651 29.2109 41.7166C29.469 41.768 29.7366 41.7416 29.9798 41.6407C30.223 41.5397 30.4309 41.3687 30.5772 41.1494C30.7234 40.93 30.8014 40.6721 30.8014 40.4082C30.801 40.0544 30.6608 39.7152 30.4113 39.4649C30.1618 39.2146 29.8235 39.0736 29.4705 39.0729V39.0743Z" fill={color} />
        <g opacity="0.3">
          <path d="M103.395 10.5796C105.617 11.8353 107.765 13.2189 109.827 14.7233C109.941 14.8064 110.07 14.8662 110.207 14.8993C110.344 14.9324 110.486 14.9381 110.625 14.9161C110.764 14.8942 110.898 14.845 111.018 14.7713C111.138 14.6977 111.243 14.601 111.326 14.4869C111.409 14.3728 111.468 14.2434 111.501 14.1062C111.534 13.969 111.54 13.8266 111.518 13.6872C111.496 13.5477 111.447 13.414 111.374 13.2936C111.3 13.1731 111.204 13.0684 111.09 12.9853C108.961 11.4323 106.744 10.0038 104.451 8.70691C104.328 8.63684 104.193 8.59177 104.053 8.57427C103.913 8.55678 103.77 8.56722 103.634 8.60499C103.498 8.64276 103.371 8.70711 103.26 8.79436C103.148 8.8816 103.055 8.99001 102.986 9.11337C102.917 9.23672 102.872 9.37259 102.856 9.51316C102.839 9.65373 102.85 9.79623 102.889 9.93248C102.927 10.0687 102.992 10.196 103.079 10.3071C103.167 10.4181 103.275 10.5108 103.399 10.5796H103.395Z" fill={color} />
          <path d="M87.1251 1.87986C75.2191 -0.977184 62.7653 -0.571364 51.0696 3.05477C50.932 3.09394 50.8037 3.1603 50.6921 3.24994C50.5805 3.33958 50.488 3.45069 50.4199 3.57673C50.3518 3.70277 50.3096 3.84119 50.2956 3.98385C50.2817 4.1265 50.2963 4.27051 50.3388 4.40739C50.3812 4.54427 50.4506 4.67126 50.5427 4.78088C50.6349 4.8905 50.7479 4.98054 50.8753 5.04569C51.0026 5.11084 51.1418 5.14979 51.2844 5.16025C51.4271 5.17071 51.5703 5.15246 51.7058 5.10658C63.0329 1.59507 75.094 1.20212 86.6248 3.96892C86.9014 4.03542 87.193 3.98907 87.4354 3.84007C87.6779 3.69108 87.8514 3.45163 87.9178 3.17442C87.9841 2.8972 87.9378 2.60492 87.7892 2.36188C87.6405 2.11883 87.4017 1.94492 87.1251 1.87842V1.87986Z" fill={color} />
          <path d="M29.5029 15.7523C29.4133 15.6434 29.303 15.5533 29.1786 15.4871C29.0542 15.4209 28.9179 15.38 28.7777 15.3667C28.6374 15.3534 28.496 15.368 28.3614 15.4095C28.2267 15.4511 28.1017 15.5189 27.9932 15.6091C26.8711 16.5404 25.7646 17.5219 24.7053 18.5263C24.6029 18.6232 24.5206 18.7394 24.463 18.8682C24.4054 18.997 24.3736 19.1359 24.3696 19.277C24.3655 19.4181 24.3893 19.5586 24.4394 19.6905C24.4895 19.8224 24.5651 19.9432 24.6618 20.0458C24.7585 20.1484 24.8743 20.231 25.0028 20.2887C25.1313 20.3465 25.27 20.3783 25.4107 20.3824C25.5515 20.3864 25.6916 20.3626 25.8233 20.3124C25.9549 20.2621 26.0753 20.1864 26.1777 20.0895C27.2055 19.1166 28.2777 18.1652 29.3641 17.2625C29.5818 17.0804 29.7187 16.8192 29.7447 16.5361C29.7707 16.2531 29.6837 15.9713 29.5029 15.7523Z" fill={color} />
          <path d="M13.6877 34.0655C13.5653 33.9958 13.4303 33.951 13.2906 33.9337C13.1508 33.9163 13.0091 33.9268 12.8734 33.9645C12.7377 34.0021 12.6107 34.0663 12.4997 34.1532C12.3888 34.2401 12.296 34.3481 12.2268 34.471C7.87933 42.1374 5.07917 50.5863 3.98569 59.3362C3.26616 65.0583 3.26616 70.8482 3.98569 76.5702C4.01837 76.8296 4.1442 77.0681 4.33972 77.2411C4.53523 77.4141 4.78697 77.5098 5.04778 77.5102C5.09556 77.5104 5.14331 77.5075 5.19073 77.5016C5.33048 77.4839 5.46531 77.4387 5.58762 77.3687C5.70993 77.2987 5.81735 77.2052 5.90354 77.0935C5.98972 76.9818 6.05301 76.8542 6.0899 76.7179C6.1268 76.5817 6.13656 76.4395 6.11852 76.2994C5.42188 70.7567 5.42188 65.1483 6.11852 59.6056C7.17811 51.135 9.88933 42.956 14.098 35.5341C14.168 35.4109 14.213 35.2749 14.2302 35.1342C14.2475 34.9934 14.2367 34.8506 14.1985 34.714C14.1604 34.5775 14.0955 34.4498 14.0078 34.3385C13.9202 34.2272 13.8114 34.1344 13.6877 34.0655Z" fill={color} />
          <path d="M38.4446 124.937C35.9161 123.471 33.4872 121.84 31.174 120.052C30.9487 119.878 30.6637 119.801 30.3817 119.837C30.0997 119.874 29.8438 120.021 29.6702 120.247C29.4965 120.473 29.4194 120.758 29.4559 121.041C29.4923 121.324 29.6393 121.58 29.8645 121.754C32.2531 123.6 34.7613 125.285 37.3724 126.798C37.6188 126.94 37.9116 126.979 38.1864 126.905C38.4612 126.831 38.6955 126.651 38.8377 126.404C38.9798 126.157 39.0183 125.863 38.9446 125.588C38.8709 125.313 38.691 125.078 38.4446 124.935V124.937Z" fill={color} />
          <path d="M85.2629 132.206C74.9198 134.455 64.1896 134.185 53.9722 131.418C53.835 131.377 53.6908 131.363 53.5483 131.379C53.4058 131.394 53.2678 131.438 53.1425 131.507C53.0172 131.577 52.907 131.671 52.8186 131.784C52.7302 131.897 52.6654 132.027 52.6278 132.166C52.5902 132.304 52.5808 132.449 52.6 132.591C52.6192 132.734 52.6667 132.871 52.7396 132.994C52.8126 133.118 52.9095 133.226 53.0247 133.311C53.1399 133.396 53.271 133.458 53.4103 133.492C63.9594 136.349 75.0385 136.628 85.7175 134.306C85.8585 134.279 85.9929 134.225 86.1126 134.145C86.2322 134.066 86.3347 133.963 86.4141 133.843C86.4934 133.724 86.548 133.589 86.5745 133.448C86.601 133.306 86.599 133.161 86.5685 133.02C86.538 132.88 86.4795 132.747 86.3968 132.629C86.314 132.512 86.2086 132.412 86.0867 132.336C85.9648 132.26 85.8291 132.21 85.6873 132.188C85.5456 132.165 85.4008 132.172 85.2615 132.206H85.2629Z" fill={color} />
          <path d="M108.104 122.354C106.583 123.393 104.997 124.379 103.395 125.285C103.148 125.425 102.968 125.658 102.892 125.932C102.817 126.205 102.852 126.498 102.991 126.746C103.13 126.993 103.361 127.175 103.634 127.253C103.907 127.33 104.199 127.295 104.447 127.157C106.102 126.222 107.735 125.204 109.307 124.131C109.541 123.97 109.701 123.723 109.753 123.444C109.805 123.165 109.745 122.876 109.586 122.641C109.426 122.407 109.18 122.245 108.902 122.191C108.624 122.137 108.336 122.196 108.101 122.354H108.104Z" fill={color} />
          <path d="M136.308 82.6814C136.031 82.6144 135.74 82.6601 135.497 82.8084C135.254 82.9566 135.08 83.1954 135.013 83.4723C133.864 88.211 132.191 92.8061 130.022 97.1715C130.383 98.3311 130.567 99.5389 130.567 100.754C133.48 95.4775 135.676 89.8347 137.097 83.9752C137.163 83.6988 137.117 83.4074 136.969 83.165C136.821 82.9225 136.583 82.7486 136.308 82.6814Z" fill={color} />
          <path d="M120.713 113.834C120.859 113.834 121.003 113.804 121.137 113.746C121.271 113.688 121.391 113.603 121.491 113.497C121.219 113.617 120.944 113.729 120.666 113.832L120.713 113.834Z" fill={color} />
          <path d="M138.727 61.8046C138.584 60.1468 138.368 58.4775 138.101 56.8427C138.051 56.5651 137.894 56.3183 137.664 56.1555C137.434 55.9928 137.149 55.9272 136.872 55.9729C136.594 56.0186 136.345 56.172 136.179 56.3998C136.014 56.6277 135.944 56.9118 135.985 57.1908C136.244 58.767 136.448 60.3903 136.591 61.9965C136.767 63.9552 136.856 65.9526 136.856 67.9313C136.856 68.2163 136.968 68.4896 137.169 68.6912C137.371 68.8927 137.643 69.0059 137.928 69.0059C138.212 69.0059 138.485 68.8927 138.686 68.6912C138.887 68.4896 139 68.2163 139 67.9313C139 65.8895 138.908 63.8277 138.727 61.8046Z" fill={color} />
        </g>
        <path d="M51.1165 50.88V45.4836V40.3782C51.1165 35.9599 54.6982 32.3782 59.1165 32.3782H75.5001M51.1165 85.9564V93.76C51.1165 98.1783 54.6982 101.76 59.1165 101.76H81.6619C86.0802 101.76 89.6619 98.1783 89.6619 93.76V72.5" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path d="M72 42H53" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M113.698 32.1958L113.698 32.196C117.615 36.1079 119.769 41.3024 119.769 46.8352C119.769 52.3682 117.615 57.5625 113.699 61.4744L113.699 61.4746C109.783 65.3872 104.582 67.5392 99.0428 67.5392C95.3422 67.5392 91.7973 66.579 88.6869 64.7797L88.1333 64.4594L87.5189 64.6372L78.7165 67.1841L81.2587 58.4151L81.438 57.7967L81.1132 57.2408C79.2894 54.1191 78.3157 50.5557 78.3157 46.8352C78.3157 41.3022 80.4693 36.1078 84.3858 32.196L84.3859 32.1959C88.3025 28.2831 93.5023 26.1312 99.0419 26.1312C104.582 26.1312 109.782 28.2832 113.698 32.1958Z" stroke={color} strokeWidth="3" />
        <path d="M110.616 52.3344L110.596 52.5153C110.515 53.2749 110.322 54.0172 110.02 54.7199C109.327 56.449 107.463 57.0931 105.32 57.0461C104.722 57.0329 104.127 56.956 103.543 56.8371L103.074 56.7422C102.648 56.6547 102.226 56.5423 101.813 56.4036L99.4569 55.5193L99.0341 55.3153C95.4306 53.5788 92.363 50.9026 90.1533 47.5708L89.0551 45.5774C88.7108 44.9515 88.4507 44.281 88.3078 43.5808C88.307 43.5767 88.3062 43.5726 88.3053 43.5684L88.2104 43.1011C88.0915 42.5156 88.0139 41.9228 88.0015 41.3257C87.9561 39.2779 88.9651 37.055 90.5679 36.2599C90.6892 36.1996 90.818 36.1517 90.9485 36.1137C91.1434 36.0559 91.3449 36.0229 91.5472 36.013C92.0294 35.9907 91.9097 35.9948 92.4224 36.0435C92.4984 36.051 92.5752 36.0617 92.6503 36.0757L92.7577 36.0955C93.2911 36.2054 93.453 36.4349 93.6627 36.9097L95.4455 40.9335C95.6511 41.3959 95.438 42.7757 94.903 43.2563L93.9938 44.0821C93.7048 44.3446 93.6396 44.774 93.8378 45.1109L94.2399 45.793C95.7576 48.3651 98.0795 50.3692 100.848 51.4955L101.585 51.7952C101.965 51.9496 102.402 51.8365 102.659 51.5153L104.271 49.5013C104.579 49.0992 105.121 48.9588 105.585 49.1644L110.109 51.1586C110.584 51.3683 110.652 51.7944 110.616 52.3328V52.3344Z" fill={color} />
        <path d="M69.1644 60.8574L69.1665 60.8598C69.7243 61.5327 70.2106 62.2644 70.6153 63.0425C71.4838 64.7148 71.9726 66.604 71.9726 68.6078C71.9726 73.8293 68.6364 78.3243 63.8762 80.1336L62.9092 80.5011V81.5357V86.4726L59.3729 83.6965L58.4466 84.8764L59.3729 83.6965L56.3215 81.3012L55.9138 80.9811H55.3953H48.3922C44.8624 80.9811 41.676 79.5884 39.3726 77.343L39.3723 77.3427C37.0698 75.0996 35.6562 72.0121 35.6562 68.6078C35.6562 61.8109 41.3212 56.2346 48.3922 56.2346H59.2375C62.7672 56.2346 65.9528 57.6272 68.2562 59.8726L68.2564 59.8728C68.5785 60.1867 68.8818 60.5149 69.1644 60.8574Z" stroke={color} strokeWidth="3" />
        <path d="M45.5838 71.5609C47.1643 71.5609 48.4455 70.2797 48.4455 68.6993C48.4455 67.1188 47.1643 65.8376 45.5838 65.8376C44.0034 65.8376 42.7222 67.1188 42.7222 68.6993C42.7222 70.2797 44.0034 71.5609 45.5838 71.5609Z" fill={color} />
        <path d="M65.4575 68.6993C65.4575 70.2791 64.1766 71.5609 62.5959 71.5609C61.0151 71.5609 59.8596 70.4001 59.7447 68.9255C59.7377 68.8509 59.7351 68.7755 59.7351 68.6993C59.7351 67.1185 61.016 65.8376 62.5959 65.8376H62.6082C64.1836 65.8447 65.4575 67.1229 65.4575 68.6993Z" fill={color} />
        <path d="M56.9518 68.6993C56.9518 69.7417 56.3942 70.6544 55.5604 71.1559C55.1308 71.4128 54.6276 71.5609 54.0902 71.5609C52.5103 71.5609 51.2285 70.2791 51.2285 68.6993C51.2285 67.1194 52.5103 65.8376 54.0902 65.8376C55.5753 65.8376 56.7957 66.9677 56.9378 68.4152C56.9474 68.509 56.9518 68.6037 56.9518 68.6993Z" fill={color} />
      </svg>
    </>
  );
}