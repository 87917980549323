import { Modal } from "antd"
import { useTranslation } from "react-i18next";

export const AllowRotationModal = ({ allowRotationModal, source }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={source === "sign" ? t("block_content.title") : t("block_content_orientation_change.title")}
            width={1000}
            footer={null}
            open={allowRotationModal}
            closable={false}
        >
            <div className="modal-container">
                {source === "sign" ? t("block_content.description") : t("block_content_orientation_change.description")}
            </div>
        </Modal>
    );
};