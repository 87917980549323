import React, { useState, useEffect, useRef } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { isMobile } from 'react-device-detect';

const DocumentPage = ({ srcImage, index, form, lineRef, zoomStyles, changePage, children, isOnPreviewForm }) => {
  const [isHorizontal, setIsHorizontal] = useState(null);
  const wrapperRef = useRef(null);

  // Check if image is horizontal as a Promise
  const checkHorizontalImage = (srcImage) => {
    return new Promise((resolve) => {
      let im = new Image();
      im.src = srcImage;
      im.onload = () => {
        resolve(im.width > im.height);
      };
    });
  };

  useEffect(() => {
    checkHorizontalImage(srcImage).then((result) => {
      setIsHorizontal(result);
    });
  }, [srcImage]);

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        if (event.touches.length === 2) {
          event.preventDefault();
        } else {
          document.body.style.overflowY = "auto";
        }
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  if (isHorizontal === null) {
    return <div></div>;
  }

  const imageElement = (
    <img
      ref={lineRef}
      alt="renderedImage"
      src={srcImage}
      className={`${form ? "pdf-images-on-preview" : "pdf-images"} ${isHorizontal ? "horizontal-page" : ""}`}
      id={`pdf-page-${index + 1}`}
      style={{ ...zoomStyles, width: '100%', height: 'auto'}}
      onClick={() => changePage(index)}
    />
  );

  return (
    <div ref={wrapperRef} className={form ? "page-image-on-preview" : "page-image"}>
      {isMobile && !isOnPreviewForm ? (
        <TransformWrapper
          pinch={{ disabled: false }}
          panning={{ disabled: true }}
          wheel={{ disabled: true }}
          doubleClick={{ disabled: true }}
          wrapStyle={{ pointerEvents: "none" }}
        >
          <TransformComponent>{imageElement}</TransformComponent>
        </TransformWrapper>
      ) : (
        imageElement
      )}
      {children}
    </div>
  );
};

export default DocumentPage;