import { useState, useEffect } from "react";

export const useKeyboardStatus = () => {
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setKeyboardOpen(window.visualViewport?.height < window.innerHeight * 0.8);
    };

    window.visualViewport?.addEventListener("resize", handleResize);
    return () => window.visualViewport?.removeEventListener("resize", handleResize);
  }, []);

  return keyboardOpen;
};