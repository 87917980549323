import React, { useEffect, useState } from 'react';

export const SupportTicketIcon = () => {
  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="151" height="147" viewBox="0 0 151 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
        <path d="M3.8978 98.7813H1.42948C1.12262 98.7813 0.828341 98.6593 0.611357 98.4421C0.394373 98.2249 0.272461 97.9303 0.272461 97.6232C0.272461 97.316 0.394373 97.0214 0.611357 96.8042C0.828341 96.587 1.12262 96.465 1.42948 96.465H3.8978C4.20466 96.465 4.49895 96.587 4.71593 96.8042C4.93291 97.0214 5.05483 97.316 5.05483 97.6232C5.05483 97.9303 4.93291 98.2249 4.71593 98.4421C4.49895 98.6593 4.20466 98.7813 3.8978 98.7813Z" fill={color}/>
        <path d="M13.4624 98.7813H10.6856C10.3787 98.7813 10.0844 98.6593 9.86745 98.4421C9.65047 98.2249 9.52855 97.9303 9.52855 97.6232C9.52855 97.316 9.65047 97.0214 9.86745 96.8042C10.0844 96.587 10.3787 96.465 10.6856 96.465H13.4624C13.7693 96.465 14.0636 96.587 14.2806 96.8042C14.4975 97.0214 14.6195 97.316 14.6195 97.6232C14.6195 97.9303 14.4975 98.2249 14.2806 98.4421C14.0636 98.6593 13.7693 98.7813 13.4624 98.7813Z" fill={color}/>
        <path d="M7.36887 104.88C7.06201 104.88 6.76773 104.758 6.55074 104.541C6.33376 104.324 6.21185 104.029 6.21185 103.722V101.251C6.21185 100.944 6.33376 100.65 6.55074 100.432C6.76773 100.215 7.06201 100.093 7.36887 100.093C7.67573 100.093 7.97001 100.215 8.187 100.432C8.40398 100.65 8.52589 100.944 8.52589 101.251V103.722C8.52589 104.029 8.40398 104.324 8.187 104.541C7.97001 104.758 7.67573 104.88 7.36887 104.88Z" fill={color}/>
        <path d="M7.36891 95.6153C7.06205 95.6153 6.76777 95.4932 6.55079 95.2761C6.3338 95.0589 6.21189 94.7643 6.21189 94.4571V91.6776C6.21189 91.3704 6.3338 91.0759 6.55079 90.8587C6.76777 90.6415 7.06205 90.5195 7.36891 90.5195C7.67578 90.5195 7.97006 90.6415 8.18704 90.8587C8.40402 91.0759 8.52594 91.3704 8.52594 91.6776V94.4571C8.52594 94.7643 8.40402 95.0589 8.18704 95.2761C7.97006 95.4932 7.67578 95.6153 7.36891 95.6153Z" fill={color}/>
        </g>
        <g opacity="0.3">
        <path d="M122.034 35.7155H119.566C119.259 35.7155 118.964 35.5935 118.747 35.3763C118.53 35.1591 118.409 34.8645 118.409 34.5574C118.409 34.2502 118.53 33.9556 118.747 33.7384C118.964 33.5213 119.259 33.3992 119.566 33.3992H122.034C122.341 33.3992 122.635 33.5213 122.852 33.7384C123.069 33.9556 123.191 34.2502 123.191 34.5574C123.191 34.8645 123.069 35.1591 122.852 35.3763C122.635 35.5935 122.341 35.7155 122.034 35.7155Z" fill={color}/>
        <path d="M131.599 35.7155H128.822C128.515 35.7155 128.221 35.5935 128.004 35.3763C127.787 35.1591 127.665 34.8645 127.665 34.5574C127.665 34.2502 127.787 33.9556 128.004 33.7384C128.221 33.5213 128.515 33.3992 128.822 33.3992H131.599C131.905 33.3992 132.2 33.5213 132.417 33.7384C132.634 33.9556 132.756 34.2502 132.756 34.5574C132.756 34.8645 132.634 35.1591 132.417 35.3763C132.2 35.5935 131.905 35.7155 131.599 35.7155Z" fill={color}/>
        <path d="M125.505 41.8145C125.198 41.8145 124.904 41.6925 124.687 41.4753C124.47 41.2581 124.348 40.9636 124.348 40.6564V38.1857C124.348 37.8786 124.47 37.584 124.687 37.3668C124.904 37.1496 125.198 37.0276 125.505 37.0276C125.812 37.0276 126.106 37.1496 126.323 37.3668C126.54 37.584 126.662 37.8786 126.662 38.1857V40.6564C126.662 40.9636 126.54 41.2581 126.323 41.4753C126.106 41.6925 125.812 41.8145 125.505 41.8145Z" fill={color}/>
        <path d="M125.505 32.5501C125.198 32.5501 124.904 32.4281 124.687 32.2109C124.47 31.9937 124.348 31.6992 124.348 31.392V28.6125C124.348 28.3053 124.47 28.0108 124.687 27.7936C124.904 27.5764 125.198 27.4543 125.505 27.4543C125.812 27.4543 126.106 27.5764 126.323 27.7936C126.54 28.0108 126.662 28.3053 126.662 28.6125V31.392C126.662 31.6992 126.54 31.9937 126.323 32.2109C126.106 32.4281 125.812 32.5501 125.505 32.5501Z" fill={color}/>
        </g>
        <path opacity="0.3" d="M21.0296 125.495C20.2878 125.495 19.5628 125.275 18.946 124.863C18.3293 124.45 17.8486 123.864 17.5648 123.178C17.2809 122.492 17.2066 121.737 17.3514 121.009C17.4961 120.281 17.8533 119.612 18.3778 119.087C18.9022 118.562 19.5704 118.205 20.2979 118.06C21.0254 117.915 21.7795 117.989 22.4648 118.273C23.1501 118.557 23.7358 119.039 24.1479 119.656C24.56 120.273 24.7799 120.999 24.7799 121.741C24.7791 122.737 24.3837 123.691 23.6806 124.395C22.9774 125.099 22.024 125.495 21.0296 125.495ZM21.0296 120.304C20.7455 120.304 20.4678 120.388 20.2316 120.546C19.9954 120.704 19.8114 120.929 19.7027 121.191C19.594 121.454 19.5655 121.743 19.621 122.022C19.6764 122.301 19.8132 122.557 20.014 122.758C20.2149 122.959 20.4708 123.096 20.7494 123.151C21.028 123.207 21.3168 123.178 21.5792 123.07C21.8417 122.961 22.066 122.777 22.2238 122.54C22.3816 122.304 22.4658 122.026 22.4658 121.741C22.466 121.553 22.4291 121.365 22.357 121.191C22.2849 121.016 22.1791 120.858 22.0457 120.724C21.9123 120.59 21.754 120.484 21.5796 120.412C21.4053 120.339 21.2183 120.302 21.0296 120.302V120.304Z" fill={color}/>
        <path opacity="0.3" d="M144.438 52.8625C143.696 52.8625 142.971 52.6423 142.354 52.2298C141.737 51.8173 141.257 51.2311 140.973 50.5451C140.689 49.8592 140.615 49.1044 140.759 48.3762C140.904 47.648 141.261 46.9792 141.786 46.4542C142.31 45.9292 142.978 45.5717 143.706 45.4268C144.433 45.282 145.188 45.3563 145.873 45.6404C146.558 45.9245 147.144 46.4057 147.556 47.023C147.968 47.6403 148.188 48.3661 148.188 49.1086C148.187 50.1038 147.791 51.0579 147.088 51.7616C146.385 52.4654 145.432 52.8612 144.438 52.8625ZM144.438 47.6709C144.154 47.6709 143.876 47.7553 143.64 47.9132C143.403 48.0712 143.219 48.2957 143.111 48.5584C143.002 48.8211 142.973 49.1102 143.029 49.389C143.084 49.6679 143.221 49.9241 143.422 50.1251C143.623 50.3262 143.879 50.4631 144.157 50.5186C144.436 50.5741 144.725 50.5456 144.987 50.4368C145.25 50.328 145.474 50.1437 145.632 49.9073C145.79 49.6709 145.874 49.3929 145.874 49.1086C145.873 48.7273 145.722 48.3617 145.453 48.0919C145.184 47.8222 144.819 47.6702 144.438 47.6694V47.6709Z" fill={color}/>
        <path opacity="0.3" d="M23.9894 64.9119C23.2477 64.9119 22.5226 64.6918 21.9059 64.2793C21.2891 63.8668 20.8084 63.2805 20.5246 62.5946C20.2407 61.9086 20.1665 61.1538 20.3112 60.4257C20.4559 59.6975 20.813 59.0286 21.3375 58.5036C21.862 57.9786 22.5303 57.6211 23.2578 57.4762C23.9853 57.3314 24.7393 57.4057 25.4246 57.6899C26.1099 57.974 26.6956 58.4551 27.1077 59.0725C27.5197 59.6898 27.7397 60.4156 27.7397 61.158C27.7385 62.1532 27.343 63.1073 26.6399 63.8111C25.9369 64.5148 24.9837 64.9107 23.9894 64.9119ZM23.9894 59.7204C23.7054 59.7204 23.4277 59.8047 23.1915 59.9627C22.9553 60.1206 22.7712 60.3452 22.6625 60.6079C22.5537 60.8705 22.5253 61.1596 22.5807 61.4385C22.6361 61.7174 22.773 61.9735 22.9739 62.1746C23.1747 62.3756 23.4306 62.5126 23.7092 62.568C23.9878 62.6235 24.2766 62.595 24.5391 62.4862C24.8015 62.3774 25.0258 62.1931 25.1837 61.9567C25.3415 61.7203 25.4257 61.4424 25.4257 61.158C25.4253 60.7767 25.2739 60.4111 25.0047 60.1414C24.7355 59.8716 24.3703 59.7197 23.9894 59.7188V59.7204Z" fill={color}/>
        <path opacity="0.3" d="M131.31 121.484C130.569 121.484 129.843 121.264 129.227 120.852C128.61 120.439 128.129 119.853 127.845 119.167C127.562 118.481 127.487 117.726 127.632 116.998C127.777 116.27 128.134 115.601 128.658 115.076C129.183 114.551 129.851 114.193 130.579 114.049C131.306 113.904 132.06 113.978 132.745 114.262C133.431 114.546 134.016 115.027 134.428 115.645C134.841 116.262 135.061 116.988 135.061 117.73C135.059 118.726 134.664 119.68 133.961 120.383C133.258 121.087 132.305 121.483 131.31 121.484ZM131.31 116.293C131.026 116.293 130.749 116.377 130.512 116.535C130.276 116.693 130.092 116.917 129.983 117.18C129.875 117.443 129.846 117.732 129.902 118.011C129.957 118.29 130.094 118.546 130.295 118.747C130.496 118.948 130.751 119.085 131.03 119.14C131.309 119.196 131.597 119.167 131.86 119.059C132.122 118.95 132.347 118.765 132.504 118.529C132.662 118.293 132.747 118.015 132.747 117.73C132.746 117.349 132.595 116.983 132.326 116.714C132.056 116.444 131.691 116.292 131.31 116.291V116.293Z" fill={color}/>
        <g opacity="0.3">
        <path d="M111.598 11.4022C113.996 12.7556 116.314 14.2466 118.54 15.868C118.789 16.0488 119.098 16.1235 119.402 16.0758C119.705 16.028 119.977 15.8616 120.157 15.6132C120.338 15.3648 120.412 15.0548 120.365 14.7513C120.317 14.4478 120.151 14.1757 119.903 13.9949C117.605 12.3212 115.213 10.7817 112.738 9.384C112.606 9.30848 112.46 9.2599 112.309 9.24105C112.157 9.2222 112.004 9.23345 111.857 9.27415C111.71 9.31486 111.573 9.38422 111.453 9.47824C111.333 9.57226 111.232 9.6891 111.157 9.82204C111.083 9.95498 111.035 10.1014 111.017 10.2529C110.999 10.4044 111.011 10.558 111.052 10.7048C111.093 10.8516 111.163 10.9888 111.258 11.1085C111.353 11.2282 111.47 11.328 111.603 11.4022H111.598Z" fill={color}/>
        <path d="M94.0404 2.02595C81.1918 -1.05313 67.7522 -0.615768 55.1305 3.29218C54.9821 3.33439 54.8435 3.40591 54.7231 3.50251C54.6026 3.59912 54.5028 3.71886 54.4293 3.8547C54.3558 3.99053 54.3102 4.13971 54.2951 4.29345C54.2801 4.4472 54.296 4.60239 54.3418 4.74991C54.3876 4.89743 54.4624 5.03429 54.5618 5.15243C54.6612 5.27057 54.7833 5.3676 54.9207 5.43782C55.0582 5.50803 55.2083 5.55001 55.3622 5.56128C55.5161 5.57256 55.6708 5.55289 55.817 5.50344C68.0409 1.71903 81.0568 1.29555 93.5005 4.27737C93.6483 4.31285 93.8016 4.31885 93.9518 4.29503C94.1019 4.2712 94.2457 4.21802 94.3753 4.13851C94.5049 4.059 94.6176 3.95472 94.7069 3.83163C94.7962 3.70854 94.8605 3.56905 94.8959 3.42112C94.9314 3.27319 94.9373 3.11971 94.9135 2.96946C94.8897 2.81921 94.8366 2.67513 94.7571 2.54543C94.6777 2.41574 94.5735 2.30297 94.4506 2.21357C94.3276 2.12418 94.1882 2.05989 94.0404 2.02441V2.02595Z" fill={color}/>
        <path d="M31.8563 16.9766C31.7595 16.8592 31.6406 16.7621 31.5064 16.6908C31.3721 16.6194 31.2251 16.5753 31.0738 16.561C30.9224 16.5467 30.7697 16.5623 30.6244 16.6072C30.4792 16.652 30.3442 16.725 30.2272 16.8222C29.0161 17.8259 27.8221 18.8836 26.6789 19.9661C26.4558 20.177 26.3254 20.4681 26.3166 20.7752C26.3078 21.0823 26.4212 21.3803 26.6319 21.6037C26.8427 21.8271 27.1334 21.9576 27.4402 21.9664C27.747 21.9753 28.0448 21.8617 28.268 21.6508C29.3772 20.6023 30.5341 19.577 31.7066 18.6042C31.9415 18.4078 32.0891 18.1263 32.1172 17.8213C32.1452 17.5163 32.0514 17.2125 31.8563 16.9766Z" fill={color}/>
        <path d="M14.7892 36.7128C14.6571 36.6377 14.5114 36.5894 14.3606 36.5707C14.2098 36.552 14.0568 36.5633 13.9103 36.6039C13.7639 36.6445 13.6269 36.7136 13.5072 36.8073C13.3874 36.9009 13.2873 37.0173 13.2126 37.1498C8.52089 45.4121 5.49897 54.5175 4.31891 63.9475C3.54243 70.1142 3.54243 76.3541 4.31891 82.5208C4.35419 82.8003 4.49006 83.0574 4.70105 83.2438C4.91204 83.4303 5.18371 83.5334 5.46517 83.5338C5.51673 83.5341 5.56827 83.531 5.61943 83.5245C5.77025 83.5055 5.91583 83.4568 6.04782 83.3814C6.17981 83.3059 6.29559 83.2051 6.3886 83.0847C6.48161 82.9644 6.54999 82.8268 6.5898 82.68C6.62961 82.5331 6.64007 82.3799 6.62061 82.229C5.86881 76.2555 5.86881 70.2112 6.62061 64.2378C7.76408 55.1089 10.6901 46.2942 15.232 38.2956C15.3075 38.1627 15.3559 38.0162 15.3745 37.8645C15.3931 37.7128 15.3815 37.5589 15.3403 37.4117C15.2991 37.2646 15.2293 37.127 15.1347 37.007C15.0401 36.8871 14.9227 36.787 14.7892 36.7128Z" fill={color}/>
        <path d="M41.5058 134.646C38.7771 133.067 36.156 131.309 33.6596 129.382C33.4166 129.195 33.1091 129.111 32.8048 129.151C32.5004 129.19 32.2241 129.349 32.0367 129.592C31.8493 129.836 31.7662 130.143 31.8055 130.448C31.8449 130.753 32.0035 131.029 32.2465 131.217C34.8242 133.206 37.5309 135.022 40.3488 136.652C40.6147 136.806 40.9308 136.847 41.2273 136.768C41.5239 136.688 41.7766 136.494 41.93 136.228C42.0835 135.961 42.125 135.645 42.0454 135.348C41.9659 135.051 41.7717 134.798 41.5058 134.645V134.646Z" fill={color}/>
        <path d="M92.0307 142.482C80.8687 144.905 69.2889 144.614 58.2625 141.632C57.9697 141.562 57.6608 141.608 57.4015 141.761C57.1421 141.915 56.9528 142.164 56.8739 142.455C56.7949 142.746 56.8325 143.056 56.9786 143.32C57.1247 143.583 57.3679 143.78 57.6563 143.867C69.0406 146.947 80.9968 147.247 92.5213 144.744C92.8132 144.671 93.0653 144.487 93.2241 144.232C93.3829 143.976 93.4359 143.668 93.3719 143.374C93.3079 143.08 93.1319 142.822 92.8812 142.655C92.6305 142.489 92.3249 142.427 92.0291 142.482H92.0307Z" fill={color}/>
        <path d="M116.68 131.864C115.039 132.983 113.328 134.046 111.599 135.021C111.333 135.173 111.138 135.424 111.056 135.719C110.975 136.014 111.013 136.329 111.163 136.596C111.313 136.863 111.563 137.059 111.857 137.142C112.152 137.225 112.467 137.189 112.734 137.04C114.521 136.031 116.282 134.935 117.979 133.778C118.231 133.605 118.404 133.338 118.46 133.038C118.517 132.737 118.452 132.426 118.28 132.173C118.108 131.92 117.842 131.745 117.542 131.687C117.242 131.629 116.931 131.693 116.677 131.864H116.68Z" fill={color}/>
        <path d="M147.118 89.1064C146.819 89.0343 146.505 89.0835 146.243 89.2433C145.981 89.4031 145.793 89.6604 145.72 89.9588C144.481 95.0659 142.674 100.018 140.334 104.723C140.724 105.972 140.922 107.274 140.922 108.583C144.066 102.897 146.436 96.8157 147.969 90.5008C148.04 90.2029 147.991 89.8889 147.831 89.6276C147.671 89.3663 147.415 89.1789 147.118 89.1064Z" fill={color}/>
        <path d="M130.288 122.68C130.445 122.681 130.601 122.649 130.745 122.586C130.89 122.524 131.02 122.432 131.127 122.317C130.834 122.447 130.536 122.568 130.237 122.679L130.288 122.68Z" fill={color}/>
        <path d="M149.728 66.6077C149.573 64.8211 149.34 63.0222 149.052 61.2603C148.998 60.9611 148.829 60.6951 148.581 60.5197C148.333 60.3444 148.026 60.2737 147.726 60.3229C147.426 60.3722 147.158 60.5374 146.979 60.783C146.8 61.0286 146.724 61.3347 146.769 61.6355C147.048 63.3341 147.269 65.0836 147.423 66.8147C147.613 68.9255 147.708 71.0781 147.708 73.2107C147.708 73.5178 147.83 73.8124 148.047 74.0296C148.264 74.2468 148.558 74.3688 148.865 74.3688C149.172 74.3688 149.466 74.2468 149.683 74.0296C149.9 73.8124 150.022 73.5178 150.022 73.2107C150.022 71.0102 149.924 68.7881 149.728 66.6077Z" fill={color}/>
        </g>
        <path d="M104.645 50.1244V51.6244H104.648V61.1898C104.648 62.3391 103.738 63.2253 102.68 63.2253H101.344H99.8445V64.7253V68.5212L95.1964 63.6858L94.7537 63.2253H94.115H77.645H76.7808L76.3474 63.9729L75.1879 65.9727L74.2836 64.0789L73.8759 63.2253H72.93H56.7236H56.0848L55.6422 63.6858L50.9941 68.5212V64.7253V63.2253H49.4941H48.1584C47.1014 63.2253 46.1902 62.3369 46.1902 61.1898V50.1244C46.1902 48.9759 47.1028 48.0889 48.1584 48.0889H102.677C103.734 48.0889 104.645 48.9772 104.645 50.1244Z" stroke={color} strokeWidth="3"/>
        <path d="M86.1482 94.1909V99.9834H64.0902V94.1909C64.0902 90.8891 66.9491 88.0664 70.6269 88.0664H79.6141C83.2276 88.0664 86.0505 90.7913 86.1482 94.0181V94.1909Z" stroke={color} strokeWidth="3"/>
        <path d="M57.5084 91.7644V95.8067H41.4166V91.7644C41.4166 89.475 43.4133 87.4698 46.0398 87.4698H52.8871C55.4657 87.4698 57.4372 89.4024 57.5084 91.6392V91.7644Z" stroke={color} strokeWidth="3"/>
        <path d="M109.419 91.7644V95.8067H93.3277V91.7644C93.3277 89.475 95.3243 87.4698 97.9509 87.4698H104.798C107.377 87.4698 109.348 89.4024 109.419 91.6392V91.7644Z" stroke={color} strokeWidth="3"/>
        <path d="M80.1843 77.9147C80.1843 80.5402 78.0447 82.6798 75.4192 82.6798C72.7936 82.6798 70.654 80.5402 70.654 77.9147C70.654 75.2891 72.7936 73.1495 75.4192 73.1495C78.0447 73.1495 80.1843 75.2891 80.1843 77.9147Z" stroke={color} strokeWidth="3"/>
        <path d="M52.7371 79.4062C52.7371 81.2094 51.2668 82.6796 49.4637 82.6796C47.6605 82.6796 46.1902 81.2094 46.1902 79.4062C46.1902 77.603 47.6605 76.1328 49.4637 76.1328C51.2668 76.1328 52.7371 77.603 52.7371 79.4062Z" stroke={color} strokeWidth="3"/>
        <path d="M104.648 79.4062C104.648 81.2094 103.178 82.6796 101.375 82.6796C99.5716 82.6796 98.1013 81.2094 98.1013 79.4062C98.1013 77.603 99.5716 76.1328 101.375 76.1328C103.178 76.1328 104.648 77.603 104.648 79.4062Z" stroke={color} strokeWidth="3"/>
        <path d="M75.4194 53.1525C74.2676 53.1525 73.331 54.0891 73.331 55.2408C73.331 56.3926 74.2676 57.3292 75.4194 57.3292C76.5711 57.3292 77.5077 56.3926 77.5077 55.2408C77.5077 54.0891 76.5711 53.1525 75.4194 53.1525Z" fill={color}/>
        <path d="M84.966 53.1525C83.8143 53.1525 82.8777 54.0891 82.8777 55.2408C82.8777 56.3926 83.8143 57.3292 84.966 57.3292C86.1178 57.3292 87.0544 56.3926 87.0544 55.2408C87.0544 54.0891 86.1178 53.1525 84.966 53.1525Z" fill={color}/>
        <path d="M66.4687 53.1525C65.3169 53.1525 64.3803 54.0891 64.3803 55.2408C64.3803 56.3926 65.3169 57.3292 66.4687 57.3292C67.6205 57.3292 68.5571 56.3926 68.5571 55.2408C68.5571 54.0891 67.6205 53.1525 66.4687 53.1525Z" fill={color}/>
        </svg>
    </>
  );
}