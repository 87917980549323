import React, { Fragment, createRef, useContext, useEffect, useRef, useState } from 'react';
import "./ImageViewer.css";

import { AdvancedFormGroup } from '../AdvancedFormGroup/AdvancedFormGroup';
import { RenderScrollContext } from '../../contexts/RenderScrollContext';
import { DOCUMENT_PAGE_VIEW } from '../../constants/GeneralConstants';
import { AdditionalSignaturesContext } from '../../contexts/AdditionalSignaturesContext';
import { SignatureBox } from '../SignatureBox/SignatureBox';
import DocumentPage from '../DocumentPage/DocumentPage';

export const ImageViewer = (props) => {

  const { images, form, setCurrentPage } = props;
  const { page, setPage } = useContext(RenderScrollContext);
  const lineRefs = useRef([]);
  const [resolvedImagesList, setResolvedImagesList] = useState([]);
  const hasForm = form?.FormDisplay;
  const isOnPreviewForm = hasForm && form?.FormDisplay.toLowerCase() === "onpreview";

  lineRefs.current = images?.map((_, i) => lineRefs.current[i] ?? createRef());

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    setPage({
      value: newPage,
      source: DOCUMENT_PAGE_VIEW
    });
  };
  const { signatureBoxes } = useContext(AdditionalSignaturesContext);

  useEffect(() => {
    const fetchImagesList = async () => {
      const imagesListPromises = images?.map(async (renderedImage, index) => {
        let signatureBoxesData = signatureBoxes ? signatureBoxes : [];

        signatureBoxesData = signatureBoxesData.filter((box) => box.Page === index + 1);

        const signatureBoxesOfPage = signatureBoxesData.map((box, i) => {
          return <SignatureBox boxData={box} key={i} />;
        });

        const imageSrc = `data:image/jpeg;base64,${renderedImage}`;

        return (
          <Fragment key={index}>
            <DocumentPage
              srcImage={imageSrc}
              index={index}
              form={form}
              lineRef={lineRefs.current[index]}
              zoomStyles={props.zoomStyles}
              changePage={changePage}
              signatureBoxesOfPage={signatureBoxesOfPage}
              isOnPreviewForm={isOnPreviewForm}
            >
              {form && <AdvancedFormGroup index={index} pagesLength={images.length} />}
              {signatureBoxesOfPage}
            </DocumentPage>
          </Fragment>
        );
      });

      const resolvedList = await Promise.all(imagesListPromises);
      setResolvedImagesList(resolvedList);
    };

    fetchImagesList();
  }, [images, form, signatureBoxes, props.zoomStyles]);

  return (
    <>
      {resolvedImagesList}
    </>
  );
}