import Helper from "../../util/helper";
import { Choice } from "../Choice/Choice";
import "./RadioButton.css";
import { useContext } from "react";
import { FormContext } from "../../contexts/FormContext";

const { Form } = require("react-bootstrap");

export const RadioButton = ({
  componentData,
  onUpdateValue,
  isAdvancedForm
}) => {
  const { isHTML, checkDisabled, getScreenDimensions } = useContext(FormContext);
  const isDisabled = checkDisabled(componentData);
  const hasError = componentData.error;

  const { id, title, choices } = componentData;

  const titleValue = isAdvancedForm ? title.disableprintpdf ? '' : title.text : title;

  const getPositionStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = title.position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  return (
    <div className={`${isAdvancedForm ? 'advanced-form' : ''} ${isDisabled ? 'disabled-container' : ''}  ${hasError ? 'error-container' : ''} radiobutton-container`}>
      {isHTML ? (
        <div style={isAdvancedForm ? getPositionStyles() : {}} dangerouslySetInnerHTML={Helper.mapInnerHtmlData(titleValue)} />
      ) : (
        <label style={isAdvancedForm ? getPositionStyles() : {}}>{titleValue}</label>
      )}
      <Form>
        {!isAdvancedForm && choices.map((choice, i) => {
          return (
            <Choice
              isAdvancedForm={isAdvancedForm}
              name={id}
              label={isAdvancedForm ? choice.title.disableprintpdf ? '' : choice.title.text : choice}
              key={i}
              choiceIndex={i} 
              parentData={componentData} 
              onUpdateValue={onUpdateValue}
              position={choice?.title?.position}
              isDisabled={isDisabled}
            />
          );
        })}
      </Form>
    </div>
  );
};
