import React, { useContext, useEffect, useRef, useState } from 'react';
import "./DocumentPreview.css";
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentService from '../../services/DocumentService';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { SmallImageViewer } from '../../components/SmallImageViewer/SmallImageViewer';
import { FIRST_LOAD, IS_FROM_BATCH_LIST, IS_SSI_AUTH_BATCH, LOADING_URL } from '../../constants/GeneralConstants';
import DownloadDocumentService from '../../services/DownloadDocumentService';
import FormS from 'react-bootstrap/Form';
import { Loader } from "../../components/Loader/Loader";
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { SimplifiedSignature } from '../../components/SignMethods/SimplifiedSignature/SimplifiedSignature';
import { SkipSMSAndDraw } from '../../components/SignMethods/SkipSMSandDraw/SkipSMSAndDraw';
import { OTPDraw } from '../../components/SignMethods/OTPDraw/OTPDraw';
import { FormOnPreview } from '../../components/FormPreviewTypes/FormOnPreview';
import { FormAfterPreview } from '../../components/FormPreviewTypes/FormAfterPreview';
import { Autofirma } from '../../components/Autofirma/Autofirma';
import { AutofirmaScripts } from '../../components/Autofirma/AutofirmaScripts';
import { RejectDocument } from '../../components/RejectDocument/RejectDocument';
import Helper from '../../util/helper';
import { Back } from '../../components/Back/Back';
import { SSIModal } from '../../components/SSIModal/SSIModal';
import { DownloadDocumentIcon } from '../../components/SvgComponents/DownloadDocumentIcon/DownloadDocumentIcon';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { CriiptoModal } from '../../components/SignMethods/CriiptoModal/CriiptoModal';
import OneShotService from '../../services/OneShotService';
import { ShowErrorModal } from '../../components/Modals/ShowErrorModal/ShowErrorModal';
import { AOCValidModal } from '../../components/SignMethods/AOCValidModal/AOCValidModal';
import { SwisscomModal } from '../../components/SignMethods/SwisscomModal/SwisscomModal';
import { RenderScrollContext } from '../../contexts/RenderScrollContext';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import DisclaimerService from '../../services/DisclaimerService';
import { DocumentPreviewDisclaimerModal } from '../../components/Modals/DocumentPreviewDisclaimerModal/DocumentPreviewDisclaimerModal';
import { AdditionalSignaturesContext } from '../../contexts/AdditionalSignaturesContext';
import { SignatureReasonIcon } from '../../components/SvgComponents/SignatureReasonIcon/SignatureReasonIcon';
import { SignatureReasonModal } from '../../components/Modals/SignatureReasonModal/SignatureReasonModal';
import { CancelIcon } from '../../components/SvgComponents/CancelIcon/CancelIcon';
import DocumentMapService from '../../services/DocumentMapService';

export const DocumentPreview = ({ form, areQuestionsAnswered, formTransform }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState({});
  const [loadedPagesList, setLoadedPagesList] = useState([]);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMorePages, setLoadingMorePages] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const desktopScrollRef = useRef(null);
  const desktopScrollFlag = useRef(false);
  const smallDesktopScrollRef = useRef(null);
  const smallDesktopScrollFlag = useRef(false);
  const mobileScrollRef = useRef(null);
  const mobileScrollFlag = useRef(false);

  const [loadedPagesListLength, setLoadedPagesListLength] = useState(0);
  const [nextPageNumberToLoad, setNextPageNumberToLoad] = useState(1);
  const [allowDownloadDocument, setAllowDownloadDocument] = useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [zoomStyles, handlers] = useImageZoom();
  const [scrollX, setScrollX] = useState({ overflowX: 'hidden' });
  const [emailId, setEmailId] = useState(null);
  const signerGuid = location.state.documents.SignerDTO.SignerGUI;
  const docGuid = location.state.documents.DocGUI;
  const email = location.state.documents.SignerDTO.eMail;
  const phoneNumber = location.state.documents.SignerDTO.PhoneNumber;
  const [documentPagesTotal, setDocumentPagesTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const isAutofirma = location.state.documents.SignerDTO.LocalSignature;
  const [simplifiedSignature, setSimplifiedSignature] = useState(false);
  const [skipSMSAndDraw, setSkipSMSAndDraw] = useState(false);
  const [otpDraw, setOTPDraw] = useState(false);
  const [formOnPreview, setFormOnPreview] = useState(false);
  const [formAfterPreview, setFormAfterPreview] = useState(false);
  const [autofirma, setAutofirma] = useState(false);
  const [openSSIModal, setOpenSSIModal] = useState(false);
  const [criiptoModal, setCriiptoModal] = useState(false);
  const [openShowErrorModal, setOpenShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loaderMessage, setLoaderMessage] = useState(t('general.loading'));
  const [aocValidModal, setAOCValidModal] = useState(false);
  const [swisscomModal, setOpenSwisscomModal] = useState(false);
  const [showModalDisclaimer, setShowModalDisclaimer] = useState(false);
  const [indexCount, setIndexCount] = useState(0);
  const [disclaimerTitle, setDisclaimerTitle] = useState('');
  const [disclaimerMessage, setDisclaimerMessage] = useState('');
  const [showSignatureReasonModal, setShowSignatureReasonModal] = useState(false);
  const [showModalErrorSelectedPage, setShowModalErrorSelectedPage] = useState(false);
  const [errorMessageSelectedPageModal, setErrorMessageSelectedPageModal] = useState('');
  const inputPageRef = useRef(null);

  const signatureDelegationInfo = location.state.documents.SignerDTO.SignatureDelegationInfo;
  const ifSignerData = location.state.documents.SignerDTO.SignerData;
  const isFromBatchList = localStorage.getItem(IS_FROM_BATCH_LIST) === "true";
  const hasForm = location.state.documents.SignerDTO.Form;
  const isFromQuestion = location.state?.isFromQuestion;
  const isBeforePreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview";
  const isAfterPreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "afterpreview";
  const isOnPreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview";
  const disclaimerInfo = location.state.documents.SignerDTO.DisclaimerInfo;
  const otpCode = location.state.otpCode;
  const attachedImages = location.state.attachedImages;
  const isFirstLoad = localStorage.getItem(FIRST_LOAD);
  const isSsiBatch = localStorage.getItem(IS_SSI_AUTH_BATCH);
  const signatureReason = location.state.documents.SignerDTO.SignatureReason;
  const showErrorModalSelectedPageTitle = t('document_detail.disclaimer.title');

  const pageContext = useContext(RenderScrollContext);

  const { getNextIncompletedBox, isSignatureBoxesFlow } = useContext(AdditionalSignaturesContext);

  // On Init
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setDocuments(location.state.documents);
    setEmailId(location.state.emailId);
    const documentContainer = document.getElementsByClassName('document-preview-container')[0];
    document.body.setAttribute('style', 'overflow-y: hidden;');

    documentContainer?.classList.add('document-preview-hidden');

    deleteDocumentRendered();
    setAllowDownloadDocument(location.state.documents.SignerDTO.AllowDownloadDocument);
    checkDisclaimer();
    DocumentMapService.resetMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentPagesTotal > 4 && inputPageRef.current) {
      inputPageRef.current.value = "1";
    }
  }, [documentPagesTotal]);

  useEffect(() => {
    onCurrentPageChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    //to improve
    setTimeout(() => {
      setInputValueWhenCurrentPageChanged();
      setScrollWhenCurrentPageChanged();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPagesListLength]);

  const checkBackNavigation = () => {
    return (ifSignerData || isFromBatchList || isBeforePreviewForm || (isFirstLoad === "false" && isAfterPreviewForm));
  }

  const getDocumentPagesRendered = async (docGuid, nextPageToRender) => {
    setLoading(true);
    await DocumentService.documentRendered(docGuid, nextPageToRender)
      .then(response => {
        const data = response.data;

        DocumentMapService.initializeMap(data.NumberOfPages);
        const newPosition = DocumentMapService.determinePagePosition(nextPageToRender, loadedPagesList.length - 1);
        const pagesData = DocumentMapService.updatePagesList(loadedPagesList, data.DocPageRendered, newPosition);
        DocumentMapService.updateMap(nextPageToRender, data.DocPageRendered.length, newPosition);

        setLoadedPagesList(pagesData);
        setLoadedPagesListLength(pagesData.length);
        setNextPageNumberToLoad(data.NextPage);
        setDocumentPagesTotal(data.NumberOfPages);

        localStorage.setItem("totalRenderedPages", data.DocPageRendered.length);
        const documentContainer = document.getElementsByClassName('document-preview-container')[0];
        document.body.setAttribute('style', 'overflow-y: auto;');
        documentContainer?.classList.remove('document-preview-hidden');
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setScrollToPosition = (position, id) => {
    const container = document.getElementById(id);
    if (container) {
      container.style.scrollBehavior = 'smooth';
      container.scrollTop = position + 20;
      setBlocked(true);

      setTimeout(() => {
        container.style.scrollBehavior = 'auto';
      }, 300);

      setTimeout(() => {
        setBlocked(false);
      }, 1000);
    }
  };

  const determineScrollPositionByPageAndTotalSize = (scrollId, page, totalPages) => {
    const container = document.getElementById(scrollId);
    if (container) {
      const pageSize = container.scrollHeight / totalPages;
      return Math.floor(pageSize * page);
    }

    return 0;
  }

  const requestMoreRenderPages = (page) => {
    setLoadingMorePages(true);
    DocumentService.documentRendered(documents.DocGUI, page - 1)
      .then(response => {
        const data = response.data;
        const newPage = page - 1;

        const newPosition = DocumentMapService.determinePagePosition(newPage, loadedPagesList.length - 1);
        const pagesData = DocumentMapService.updatePagesList(loadedPagesList, data.DocPageRendered, newPosition);
        DocumentMapService.updateMap(newPage, data.DocPageRendered.length, newPosition);

        setLoadedPagesList(pagesData);
        setLoadedPagesListLength(pagesData.length);
        setNextPageNumberToLoad(data.NextPage);
        localStorage.setItem("page", page);
        const totalRenderedPages = loadedPagesListLength + data.DocPageRendered.length;
        localStorage.setItem("totalRenderedPages", totalRenderedPages);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingMorePages(false);
      });
  }

  const createDownloadElementAndClick = (fileURL, fileName) => {
    var anchorElement = window.document.createElement('a');
    anchorElement.href = fileURL;
    anchorElement.target = '_self';
    anchorElement.download = fileName;
    var event = window.document.createEvent("MouseEvents");
    event.initEvent("click", true, false);
    anchorElement.dispatchEvent(event);
  }

  const handlerDownloadDocument = () => {
    DownloadDocumentService.downloadDocument(documents.DocGUI)
      .then(response => {
        const fileName = documents.FileName;
        const docContent = response.data.DocContent;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(docContent);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: 'application/pdf' });
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
          var newBlob = new Blob([Helper.base64ToArrayBuffer(docContent)], { type: 'application/octet-stream' });
          var fileURL = window.URL.createObjectURL(newBlob);
          createDownloadElementAndClick(fileURL, fileName);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  function useImageZoom(maxZoomLevel = 5) {
    const minZoomLevel = 1;
    function zoomIn() {
      setZoomLevel(zoomLevel =>
        zoomLevel < maxZoomLevel ? zoomLevel + 1 : zoomLevel
      );
    }

    function zoomOut() {
      setZoomLevel(zoomLevel =>
        zoomLevel > minZoomLevel ? zoomLevel - 1 : minZoomLevel
      );
    }

    function resetZoom() {
      setZoomLevel(minZoomLevel);
    }

    const zoomStyles = {
      transform: `scale(${zoomLevel})`,
      transformOrigin: zoomLevel > 1 ? 'top left' : 'center',
    };

    const handlers = {
      zoomIn,
      zoomOut,
      resetZoom
    }

    return [zoomStyles, handlers];
  }

  const onChangeHandler = (event) => {
    const level = parseFloat(event.target.value);
    setZoomLevel(level);
    setScrollX({ overflowX: 'hidden' });
    if (level > 1) {
      setScrollX({ overflowX: 'scroll' });
    }
  };

  const startCorrectSignMethod = () => {
    const { SkipSMS, SkipSigDraw, AuthWithSSI, AuthCriipto, AuthWithAOCValid, AuthWithSwisscom, OneShot } = documents.SignerDTO;

    const isSimplifiedSignature = SkipSMS && SkipSigDraw;

    //OneShot Flow
    if (OneShot) {
      oneShotFlow();
      return;
    }

    // SSI Flow
    if (AuthWithSSI && !isSsiBatch) return setOpenSSIModal(true);

    // Criipto Flow
    if (AuthCriipto) return setCriiptoModal(true);

    // AOCValid Flow
    if (AuthWithAOCValid) return setAOCValidModal(true);

    // Swisscom Flow
    if (AuthWithSwisscom) return setOpenSwisscomModal(true);

    // Simplified signature flow
    if (isSimplifiedSignature) return setSimplifiedSignature(true);

    // SMS, Draw flow
    if (SkipSMS && !SkipSigDraw) return setSkipSMSAndDraw(true);

    // OTP + Draw flow
    return redirectToSMSMethod();
  }

  const redirectToSMSMethod = () => {
    const link = phoneNumber ? '/select-method-otp' : '/customsms';
    navigate(link, {
      state: { documents, emailId }
    });
  };

  const handleSignButtonClick = async () => {
    if (isSignatureBoxesFlow) {
      const nextBox = getNextIncompletedBox();

      if (nextBox) {
        const pageToScroll = nextBox.Page - 1;

        setScrollToPosition(
          determineScrollPositionByPageAndTotalSize('scroll-image', pageToScroll, loadedPagesListLength),
          'scroll-image');

        return;
      }
    }

    const firstLoad = localStorage.getItem(FIRST_LOAD);

    // AdvancedPreview flow
    if (documents.SignerDTO.Form && (!formDisplayIsNullOrEmpty() && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "afterpreview" && firstLoad === "true")) return setFormAfterPreview(true);

    // OnPreview flow
    if (documents.SignerDTO.Form && (!formDisplayIsNullOrEmpty() && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview")) return setFormOnPreview(true);
    setLoadingMorePages(true);

    // Autofirma
    if (typeof isAutofirma !== 'undefined' && isAutofirma !== 'no') return setAutofirma(true);

    //Continue with correct sign method
    startCorrectSignMethod();
  };

  const formDisplayIsNullOrEmpty = () => {
    return (documents.SignerDTO.Form.FormDisplay === null || documents.SignerDTO.Form.FormDisplay === '');
  };

  const handleBack = () => {
    let link = "/loading" + localStorage.getItem(LOADING_URL);
    if (documents.SignerDTO.SignerData) {
      link = "/signer-data";
    }

    if (isBeforePreviewForm) {
      link = "/questions"
    }

    navigate(link, {
      state: {
        documents: documents,
        emailId: emailId,
        isFromDocument: true
      }
    });
  };

  const handleSignatureDelegation = () => {
    navigate("/signature-delegation", {
      state: {
        documents: documents,
        emailId: emailId,
        signatureDelegationInfo: signatureDelegationInfo
      }
    });
  };

  const oneShotFlow = () => {
    setLoaderMessage(t('document_detail.one_shot.generating_signature_request'));
    OneShotService.createRequest(documents.SignerDTO.SignerGUI)
      .then(res => {
        setLoaderMessage(t('document_detail.sending'));
        const requestId = res.data.RequestId;
        OneShotService.sendOTP(documents.SignerDTO.SignerGUI, requestId)
          .then(response => {
            setLoadingMorePages(false);
            if (!response) {
              setOpenShowErrorModal(true);
              const errorMessage = t('document_detail.one_shot.send_otp_error_message');
              setErrorMessage(errorMessage);
              return;
            }

            navigate("/sms", {
              state: {
                documents,
                phoneNumber: phoneNumber,
                emailId,
                requestId
              }
            });

          })
          .catch(error => {
            setLoadingMorePages(false);
            setOpenShowErrorModal(true);
            const errorMessage = t('document_detail.one_shot.send_otp_error_message');
            setErrorMessage(errorMessage);
          });
      })
      .catch(err => {
        setLoadingMorePages(false);
        setOpenShowErrorModal(true);
        const errorMessage = t('document_detail.one_shot.create_request_error_message');
        setErrorMessage(errorMessage);
      });
  };

  const deleteDocumentRendered = async () => {
    if (isFirstLoad === "true" && (isAfterPreviewForm || isOnPreviewForm)) {
      await DocumentService.deleteDocumentRendered(docGuid)
        .then(response => {
          getDocumentPagesRendered(docGuid, nextPageNumberToLoad);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      await getDocumentPagesRendered(docGuid, nextPageNumberToLoad);
    }
  }

  const checkDisclaimer = () => {
    setShowModalDisclaimer(false);
    if (disclaimerInfo.Display && isFromQuestion) {
      getDisclaimerMessage();
      return;
    }
  }

  const getDisclaimerMessage = () => {
    const disclaimerTypes = disclaimerInfo.DisclaimerTypes;
    if ((indexCount + 1) <= disclaimerTypes.length) {
      const type = disclaimerTypes[indexCount];
      DisclaimerService.getDisclaimer(signerGuid, type)
        .then(
          response => {
            setDisclaimerTitle(response.data.Title);
            setDisclaimerMessage(response.data.Text);
            setShowModalDisclaimer(true);
            setIndexCount(prevCount => prevCount + 1);
          }
        );
      return;
    }
  }

  const getSignButtonText = () => {
    const arePendingBoxes = !!getNextIncompletedBox();

    if (arePendingBoxes) return t("additional_signatures.next_signature");

    return t('general.sign');
  }

  const handlerSignatureReason = () => {
    setShowSignatureReasonModal(true);
  }

  const getTruncatedFileName = (filename) => {
    let splittedFilename = filename?.split('');

    const lettersAmount = splittedFilename?.length;

    const first10Chars = splittedFilename?.splice(0, 15);
    splittedFilename = filename?.split('');

    const last6Chars = splittedFilename?.splice(lettersAmount - 6, lettersAmount);

    return `${first10Chars.join('')}...${last6Chars.join('')}`;
  }

  const isMobileAndDelegationNotAllowed = () => {
    return !(isMobile && signatureDelegationInfo.Allow);
  }

  const handleFormOnPreviewError = (message) => {
    setOpenShowErrorModal(true);
    setErrorMessage(message);
  };

  const checkDownloadAndReason = () => {
    return !allowDownloadDocument && signatureReason === null;
  }

  const onInputChanged = (e) => {
    const value = e.target.value;
    const pageNumber = parseInt(value, 10);

    if (!pageNumber || pageNumber < 1 || pageNumber > documentPagesTotal) {
      const errorModalMessage = t('document_detail.error_modal_selected_page', { totalPages: documentPagesTotal });
      setErrorMessageSelectedPageModal(errorModalMessage);
      setShowModalErrorSelectedPage(true);
      return;
    }

    setCurrentPage(pageNumber);
  };

  const onCurrentPageChanged = () => {
    const pageToFetch = DocumentMapService.determineNeighborPageNumberToFetch(currentPage);

    if (DocumentMapService.isPageLoaded(currentPage) && pageToFetch === -1) {
      setInputValueWhenCurrentPageChanged();
      setScrollWhenCurrentPageChanged();
      return;
    }

    if (!DocumentMapService.isPageLoaded(currentPage)) {
      requestMoreRenderPages(currentPage);
      return;
    }

    if (pageToFetch !== -1) {
      requestMoreRenderPages(pageToFetch);
    }
  }

  const onDesktopScrollChanged = () => {
    if (desktopScrollFlag.current) {
      desktopScrollFlag.current = false;
      return;
    }
    const pageNumber = DocumentMapService.getPageNumberByScroll("scroll-image", loadedPagesListLength);
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  }

  const onSmallDesktopScrollChanged = () => {
    if (smallDesktopScrollFlag.current) {
      smallDesktopScrollFlag.current = false;
      return;
    }
    const pageNumber = DocumentMapService.getPageNumberByScroll("scroll-small-image", loadedPagesListLength);
    setCurrentPage(pageNumber);
  }

  const onMobileScrollChanged = () => {
    if (mobileScrollFlag.current) {
      mobileScrollFlag.current = false;
      return;
    }
    const pageNumber = DocumentMapService.getPageNumberByScroll("view-mobile", loadedPagesListLength);
    setCurrentPage(pageNumber);
  }

  const setInputValueWhenCurrentPageChanged = () => {
    //Check input value
    if (inputPageRef.current && inputPageRef.current.value !== String(currentPage)) {
      inputPageRef.current.value = String(currentPage);
    }
  }

  const setScrollWhenCurrentPageChanged = () => {
    if (isDesktop) {
      DocumentMapService.setScrollByPageNumber(desktopScrollRef, currentPage, loadedPagesListLength, desktopScrollFlag);
      DocumentMapService.setScrollByPageNumber(smallDesktopScrollRef, currentPage, loadedPagesListLength, smallDesktopScrollFlag);
      return;
    }

    DocumentMapService.setScrollByPageNumber(mobileScrollRef, currentPage, loadedPagesListLength, mobileScrollFlag);
  }

  return (
    <>
      {loading && <Loader />}
      {simplifiedSignature && <SimplifiedSignature documents={documents} signerGuid={signerGuid} setLoadingMorePages={setLoadingMorePages} emailId={emailId} docGuid={docGuid} email={email} />}
      {skipSMSAndDraw && <SkipSMSAndDraw documents={documents} emailId={emailId} setLoadingMorePages={setLoadingMorePages} />}
      {otpDraw && <OTPDraw documents={documents} emailId={emailId} setLoadingMorePages={setLoadingMorePages} otpCode={otpCode} attachedImages={attachedImages} setOTPDraw={setOTPDraw} />}
      {autofirma && <Autofirma startCorrectSignMethod={startCorrectSignMethod} signerDTO={documents.SignerDTO} setOTPDraw={setOTPDraw} emailId={emailId} email={email} docGuid={docGuid} setAutofirma={setAutofirma} />}
      <AutofirmaScripts />
      <Container className="document-preview-container">
        <Row>
          <Col md={12} lg={12} className="document-preview-title">
            {checkBackNavigation() &&
              <Back handleBack={handleBack} />
            }
            {!checkBackNavigation() && <HandIcon />}
            <span className="signer-name">
              <strong>
                {isMobile ? Helper.getTruncatedSignerName(documents?.SignerDTO?.SignerName) : documents?.SignerDTO?.SignerName},{" "}
              </strong>{t('document_detail.warning')}</span>
          </Col>
        </Row>
        {(isMobile || isTablet) && <hr></hr>}
        <Row className="align-items-center mobile-document-name">
          <Col md={8} lg={9} xs={7} className="document-name" style={{ ...(isMobile && checkDownloadAndReason() ? { margin: 'unset', marginTop: '4%', marginBottom: '4%' } : {}) }}>
            <span>{documents?.FileName?.length > 25 ? getTruncatedFileName(documents?.FileName) : documents?.FileName}</span>
          </Col>
          <Col lg={3} xs={5} md={4} className='toolbar'>
            {signatureReason !== null &&
              <span onClick={handlerSignatureReason} style={{ cursor: 'pointer' }}>
                <SignatureReasonIcon />
              </span>
            }
            {(isDesktop) &&
              <>
                {(!form && !isSignatureBoxesFlow) && <FormS.Select id="scale-zoom" onChange={onChangeHandler} defaultValue={1} className='scale-zoom'>
                  <option value="0.75">50%</option>
                  <option value="0.90">75%</option>
                  <option value="1">100%</option>
                  <option value="1.25">125%</option>
                  <option value="1.5">150%</option>
                </FormS.Select>}
              </>
            }
            {allowDownloadDocument &&
              <span onClick={handlerDownloadDocument} style={{ cursor: 'pointer' }}>
                <DownloadDocumentIcon />
              </span>
            }
          </Col>
        </Row>
        <Row>
          {(isDesktop) &&
            <>
              <Col md={3} sm={3} lg={3}>
                <div id="scroll-small-image" ref={smallDesktopScrollRef} className="pdf-container-small" onScroll={onSmallDesktopScrollChanged}>
                  <SmallImageViewer
                    images={loadedPagesList}
                    setCurrentPage={setCurrentPage}
                  >
                  </SmallImageViewer>
                </div>
              </Col>
              <Col md={1} sm={1} lg={1}></Col>
              <Col md={8} sm={8} lg={8} xs={12} id="desktop-container">
                <div id="scroll-image" ref={desktopScrollRef} onScroll={onDesktopScrollChanged} className="pdf-container" style={scrollX}>
                  <ImageViewer
                    form={form}
                    images={loadedPagesList}
                    zoomStyles={zoomStyles}
                    setCurrentPage={setCurrentPage}
                  >
                  </ImageViewer>
                </div>
                <Col className="number-page">
                  {documentPagesTotal > 4 ? (
                    <div className="tooltip-wrapper">
                      <strong>
                        <input
                          ref={inputPageRef}
                          className="page-selector"
                          type="text"
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Enter'
                            ];
                            if (
                              !allowedKeys.includes(e.key) &&
                              !/^\d$/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                            if (e.key === 'Enter') {
                              onInputChanged(e);
                            }
                          }}
                        />
                        <span style={{ marginRight: "5px" }}>/</span>
                        <span>{documentPagesTotal}</span>
                      </strong>
                      <div className="tooltip">{t('document_detail.go_to_page')}</div>
                    </div>
                  ) : (
                    <strong><span className="current-number"> {currentPage}</span> <span style={{ marginRight: "5px" }}>/</span><span>{documentPagesTotal} </span></strong>
                  )}
                </Col>
              </Col>
            </>
          }
          {
            (isMobile || isTablet) &&
            <>
              <Col sm={12}>
                <div id="view-mobile" ref={mobileScrollRef} className="pdf-container" onScroll={onMobileScrollChanged} >
                  <div className="view-mobile-doc">
                    <ImageViewer
                      form={form}
                      images={loadedPagesList}
                      zoomStyles={zoomStyles}
                      setCurrentPage={setCurrentPage}
                    >
                    </ImageViewer>
                  </div>
                </div>
              </Col>
              <Col className="number-page">
                {documentPagesTotal > 4 ? (
                  <div className="tooltip-wrapper">
                    <strong>
                      <input
                        ref={inputPageRef}
                        className="page-selector"
                        type="text"
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Enter'
                          ];
                          if (
                            !allowedKeys.includes(e.key) &&
                            !/^\d$/.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                          if (e.key === 'Enter') {
                            onInputChanged(e);
                          }
                        }}
                      />
                      <span style={{ marginRight: "5px" }}>/</span>
                      <span>{documentPagesTotal}</span>
                    </strong>
                    <div className="tooltip">{t('document_detail.go_to_page')}</div>
                  </div>
                ) : (
                  <strong><span className="current-number"> {currentPage}</span> <span style={{ marginRight: "5px" }}>/</span><span>{documentPagesTotal} </span></strong>
                )}
              </Col>
            </>
          }
        </Row>
        <Row className={`action-buttons mobile-button document-preview-buttons ${signatureDelegationInfo.Allow ? 'delegation-buttons-container' : ''}`}>
          {!signatureDelegationInfo.Allow &&
            <Col md={1} lg={1}></Col>
          }
          <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
            <button type="button" className={`btn btn-secondary btn-cancel btn-mobile ${!isMobileAndDelegationNotAllowed() ? 'button-without-icons' : ''}`} onClick={() => setOpenRejectModal(true)}>
              <span className={`document-preview-text ${!isMobileAndDelegationNotAllowed() ? 'centered-text' : ''}`}> {t('general.reject')} </span>
              {isMobileAndDelegationNotAllowed() && <CancelIcon />}
            </button>
          </Col>
          <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
            <button disabled={loadingMorePages} type="button" className={`btn btn-primary btn-active btn-mobile ${!isMobileAndDelegationNotAllowed() ? 'button-without-icons' : ''}`} onClick={() => handleSignButtonClick()}>
              <span className={`document-preview-text ${!isMobileAndDelegationNotAllowed() ? 'centered-text' : ''}`}> {getSignButtonText()} </span>
              {isMobileAndDelegationNotAllowed() &&
                <ArrowIcon disabled={loadingMorePages} />
              }
            </button>
          </Col>
          {signatureDelegationInfo.Allow &&
            <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
              <button disabled={loadingMorePages} type="button" className={`btn btn-primary btn-active btn-mobile btn-delegate ${!isMobileAndDelegationNotAllowed() ? 'button-without-icons' : ''}`} onClick={() => handleSignatureDelegation()}>
                <span className={`document-preview-text ${!isMobileAndDelegationNotAllowed() ? 'centered-text' : ''}`}> {t('signature_delegation.delegate')} </span>
                {isMobileAndDelegationNotAllowed() &&
                  <ArrowIcon secondayButton={true} disabled={loadingMorePages} />
                }
              </button>
            </Col>
          }
          {!signatureDelegationInfo.Allow &&
            <Col md={1} lg={1}></Col>
          }
        </Row>
      </Container>
      {
        loadingMorePages &&
        <div className='loading-pages-container'>
          <Loader loaderMessage={loaderMessage} />
        </div>
      }
      {formOnPreview && <FormOnPreview setLoadingMorePages={setLoadingMorePages} areQuestionsAnswered={areQuestionsAnswered} startCorrectSignMethod={startCorrectSignMethod} signerGuid={signerGuid} formTransform={formTransform} form={form} setFormOnPreview={setFormOnPreview} formOnPreviewError={handleFormOnPreviewError} isAutofirma={isAutofirma} setAutofirma={setAutofirma} />}
      {formAfterPreview && <FormAfterPreview emailId={emailId} documents={documents} />}
      <RejectDocument openRejectModal={openRejectModal} setOpenRejectModal={setOpenRejectModal} signerGuid={signerGuid} emailId={emailId} docGuid={docGuid} email={email} />
      <SSIModal signerGuid={signerGuid} openSSIModal={openSSIModal} setOpenSSIModal={setOpenSSIModal} docGuid={docGuid} />
      <CriiptoModal criiptoProvider={documents?.SignerDTO?.AuthCriipto} signerGuid={signerGuid} openCriiptoModal={criiptoModal} setOpenCriiptoModal={setCriiptoModal} setParentLoading={setLoadingMorePages} />
      <ShowErrorModal openShowErrorModal={openShowErrorModal} setOpenShowErrorModal={setOpenShowErrorModal} errorMessage={errorMessage} />
      <AOCValidModal signerGuid={signerGuid} openAOCValidModal={aocValidModal} setOpenAOCValidModal={setAOCValidModal} setParentLoading={setLoadingMorePages} />
      <SwisscomModal signerGuid={signerGuid} openSwisscomModal={swisscomModal} setOpenSwisscomModal={setOpenSwisscomModal} phoneNumber={documents?.SignerDTO?.PhoneNumber} email={email} emailId={emailId} docGuid={docGuid} setParentLoading={setLoadingMorePages} />
      <DocumentPreviewDisclaimerModal showModalDisclaimer={showModalDisclaimer && !loading} setShowModalDisclaimer={setShowModalDisclaimer} title={disclaimerTitle} message={disclaimerMessage} />
      <SignatureReasonModal showSignatureReasonModal={showSignatureReasonModal} setShowSignatureReasonModal={setShowSignatureReasonModal} reason={signatureReason} />
      <DocumentPreviewDisclaimerModal showModalDisclaimer={showModalErrorSelectedPage} setShowModalDisclaimer={setShowModalErrorSelectedPage} title={showErrorModalSelectedPageTitle} message={errorMessageSelectedPageModal} />
    </>
  );
};
