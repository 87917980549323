import React, { useState } from 'react';
import "./Support.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SupportTicketService from '../../services/SupportTicketService';
import { Loader } from '../../components/Loader/Loader';
import { Back } from '../../components/Back/Back';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { SupportTicketIcon } from '../../components/SvgComponents/SupportTicketIcon/SupportTicketIcon';

const MAX_ADDITIONAL_INFO_CHARACTERS = 500;

export const Support = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  var isPhoneNumberCustom = location.state?.isPhoneNumberCustom;
  var isCallChannel = location.state?.isCallChannel;
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const widthIcon = 150;
  const heightIcon = 147;

  const [supportTicketInfo, setSupportTicketInfo] = useState({
    additionalInfo: '',
    email: '',
    phone: ''
  });

  const { additionalInfo, email, phone } = supportTicketInfo;

  const additionalInfoLength = additionalInfo.length;

  const isFieldLongerThanMax = additionalInfoLength > MAX_ADDITIONAL_INFO_CHARACTERS;

  const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  const isValidPhone = /^\+\d{1,4}\d{6,10}$/.test(phone);

  const isDisabledButton =
    additionalInfo.length === 0 ||
    email.length === 0 ||
    phone.length === 0 ||
    !isValidEmail ||
    !isValidPhone || 
    isFieldLongerThanMax;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupportTicketInfo({
      ...supportTicketInfo,
      [name]: value
    });
  };

  const handleBack = () => {
    let link;
    if (isPhoneNumberCustom || isCallChannel) {
      link = "/customsms";
      navigate(link, {
        state: {
          documents: location.state.documents,
          phoneNumber: location.state.phoneNumber,
          emailId: location.state.emailId
        }
      });
      return;
    }

    if (!isPhoneNumberCustom && !isCallChannel) {
      link = "/sms";
    }
    navigate(link, {
      state: {
        documents: location.state.documents,
        phoneNumber: location.state.phoneNumber,
        emailId: location.state.emailId
      }
    });
  }

  const sendSupportTicket = () => {
    setLoading(true);
    const signerGUI = location.state.documents.SignerDTO.SignerGUI;
    const message = `(${email}, ${phone}): ${additionalInfo}`;

    const body = {
      "Code": "SMS_NOTRECEIVED",
      "SignerGUID": signerGUI,
      "AdditionalInfo": message
    };

    SupportTicketService.sendSupportTicket(body)
      .then(() => {
        setLoading(false);
        setSuccessMessage(true);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {loading && <Loader />}
      {!loading &&
        <Container className={!successMessage ? "document-preview-container" : ""}>
          {!successMessage &&
            <div>
              <Row>
                <Col md={12} lg={12} className="document-preview-title">
                  <Back handleBack={handleBack} />
                </Col>
                <Col md={2} xs={1}></Col>
                <Col md={12} xs={10} lg={12} className="justify-content-center container-sms-box-2">
                  <Row>
                    <Col md={4} lg={4}>
                      <SupportTicketIcon width={widthIcon} height={heightIcon} />
                    </Col>
                    <Col md={8} lg={8} className="col-align support-info-container">
                      <h3 className='support-title'>{t('wizard.open_support_ticket')}</h3>
                      <Form className="phone-number-form">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>{t('support.step1_text1')}</Form.Label>
                          <Form.Label>{t('support.step1_text2')}</Form.Label>
                          <Form.Control as="textarea" name="additionalInfo" rows={3} value={additionalInfo} onChange={handleChange} />
                          <span className={`characters-counter ${isFieldLongerThanMax ? "error-message" : ""}`}>{additionalInfoLength}/{MAX_ADDITIONAL_INFO_CHARACTERS}</span>
                        </Form.Group>
                        <div className='support-new-inputs-container'>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control as="input" type="email" placeholder={t('wizard.email')} name='email' rows={3} value={email} onChange={handleChange} />
                             {(email && !isValidEmail) && <span className="error-message">{t('signature_delegation.email_invalid_format')}</span>}
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control as="input" type="text" placeholder={t('wizard.contact_number')}  name='phone' rows={3} value={phone} onChange={handleChange} />
                             {(phone && !isValidPhone) && <span className="error-message">{t('sms.custom_error_not_valid_phone_number')}</span>}
                          </Form.Group>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={0}></Col>
                <Col md={4} xs={12} className="button-margin button-only">
                  <button type="button" className="btn btn-primary btn-active phone-number-button" disabled={isDisabledButton} onClick={() => sendSupportTicket()}>
                    <span className="cancel-text">{t('sms.custom_send')}</span>
                    <ArrowIcon disabled={isDisabledButton} />
                  </button>
                </Col>
              </Row>
            </div>
          }
          {successMessage &&
            <Row className="container-row">
              <Col md={2}></Col>
              <Col md={8} xs={12} className="justify-content-center container-box loading-box">
                <Row>
                  <Col md={12} style={{paddingBottom: '5%'}}>
                    <SupportTicketIcon />
                  </Col>
                  <Col md={12}>
                    <span>{t('support.step2_text1')}</span>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
          }
        </Container>
      }
    </>
  );
}

