import React, { useContext, useEffect, useState } from 'react';
import "./ChooseOtpMethod.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { BatchContext } from '../../contexts/BatchContext';
import { Back } from '../../components/Back/Back';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { OtpSmsIcon } from '../../components/SvgComponents/OtpSmsIcon/OtpSmsIcon';
import { OtpWhatsappIcon } from '../../components/SvgComponents/OtpWhatsappIcon/OtpWhatsappIcon';
import { isMobile } from 'react-device-detect';
import EmailSignatureService from '../../services/EmailSignatureService';
import { Loader } from '../../components/Loader/Loader';

export const ChooseOtpMethod = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [widthIcon, setWidthIcon] = useState(150);
  const [heightIcon, setHeightIcon] = useState(147);
  const documentsState = location.state.documents;
  const customPhoneNumber = location.state.phoneNumber;
  const documents = Array.isArray(documentsState) ? documentsState[0] : documentsState;
  const emailId = location.state.emailId;
  const [selectedMethod, setSelectedMethod] = useState('sms');
  const [loading, setLoading] = useState(false);

  const { isBatchMultisignature } = useContext(BatchContext);

  // On Init
  useEffect(() => {
    if (isMobile) {
      setWidthIcon(120);
      setHeightIcon(115);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    let link = '/document';
    if (documents.SignerDTO.Form && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

    if (isBatchMultisignature) link = '/documents';

    navigate(link, {
      state: {
        documents,
        emailId
      }
    });
  }

  const handleMethodOtp = () => {
    setLoading(true);
    if (selectedMethod === 'sms') {
      sendOtpBySMS();
      return;
    }
    sendOtpByWhatsapp();
  }

  const sendOtpBySMS = () => {
    const phoneNumber = documents.SignerDTO.PhoneNumber;
    let body;
    if (phoneNumber) {
      body = {
        "PhoneNumber": phoneNumber
      };
    } else {
      body = {
        "PhoneNumber": customPhoneNumber
      };
    }

    EmailSignatureService.sendOTPSms(emailId, body)
      .then((response) => {
        const data = response.data;
        let link = data.Channel.includes('SMS') ? "/sms" : "/call";
        navigate(link, {
          state: {
            documents,
            phoneNumber: phoneNumber ? phoneNumber : customPhoneNumber,
            emailId,
            selectedMethod
          }
        });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }

  const sendOtpByWhatsapp = () => {
    const phoneNumber = documents.SignerDTO.PhoneNumber

    let body;
    if (phoneNumber) {
      body = {
        "PhoneNumber": phoneNumber
      };
    } else {
      body = {
        "PhoneNumber": customPhoneNumber
      };
    }

    EmailSignatureService.sendOTPWhatsapp(emailId, body)
      .then((response) => {
        let link = "/sms";
        navigate(link, {
          state: {
            documents,
            phoneNumber: phoneNumber ? phoneNumber : customPhoneNumber,
            emailId,
            selectedMethod
          }
        });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });

  }

  return (
    <>
      {loading && <Loader />}
      {!loading &&
        <Container className="document-preview-container">
          <Row>
            <Col md={12} lg={12} className="document-preview-title">
              <Back handleBack={handleBack} />
            </Col>
            < Col md={2} xs={1}></Col>
            <Row className="justify-content-center container-sms-box-2 select-method-otp">
              <Col md={12} lg={12} style={{ height: '0%' }}>
                <h4 className="sms-title">{t('sms.question_receive_code')}</h4>
              </Col>
              <Col md={5} lg={5} xs={10}
                className={`icon-method-box d-flex align-items-center justify-content-center ${selectedMethod === 'sms' ? 'selected' : 'disabled'}`}
                onClick={() => setSelectedMethod('sms')}>
                <OtpSmsIcon width={widthIcon} height={heightIcon} disabled={selectedMethod !== 'sms'} />
                <span className="text-method"> {t('sms.option_sms')} </span>
              </Col>
              <Col md={1} lg={1} xs={0}></Col>
              <Col md={5} lg={5} xs={10} className={`icon-method-box icon-whatsapp d-flex align-items-center justify-content-center ${selectedMethod === 'whatsapp' ? 'selected' : 'disabled'}`} onClick={() => setSelectedMethod('whatsapp')}>
                <OtpWhatsappIcon width={widthIcon} height={heightIcon} disabled={selectedMethod !== 'whatsapp'} />
                <span className="text-method"> {t('sms.option_whatsapp')} </span>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md={4} xs={0}></Col>
            <Col md={4} xs={12} className="button-margin button-only">
              <button type="button" className="btn btn-primary btn-active phone-number-button" onClick={() => handleMethodOtp()}>
                <span className="cancel-text">{t('sms.custom_send')}</span>
                <ArrowIcon />
              </button>
            </Col>
          </Row>
        </Container >
      }
    </>
  );
}

