import React, { useEffect, useState } from 'react';

export const OtpSmsIcon = ({ width, height, disabled }) => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    let color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    if (disabled) color = '#9C9C9C';
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      <svg width={width} height={height} viewBox="0 0 139 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74.7782 42.4H53.1927" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M67.0082 60.8573L67.0102 60.8597C67.568 61.5326 68.0543 62.2643 68.4591 63.0424C69.3275 64.7147 69.8164 66.6039 69.8164 68.6077C69.8164 73.8292 66.4801 78.3242 61.72 80.1334L60.7529 80.501V81.5356V86.4725L57.2166 83.6964L56.2904 84.8763L57.2166 83.6964L54.1653 81.3011L53.7575 80.981H53.2391H46.2359C42.7062 80.981 39.5197 79.5883 37.2164 77.3429L37.216 77.3426C34.9135 75.0995 33.5 72.012 33.5 68.6077C33.5 61.8108 39.1649 56.2345 46.2359 56.2345H57.0813C60.611 56.2345 63.7966 57.627 66.1 59.8725L66.1001 59.8727C66.4222 60.1865 66.7255 60.5148 67.0082 60.8573Z" stroke={color} strokeWidth="3" />
        <path d="M43.4276 71.5611C45.008 71.5611 46.2892 70.2798 46.2892 68.6994C46.2892 67.1189 45.008 65.8378 43.4276 65.8378C41.8471 65.8378 40.5659 67.1189 40.5659 68.6994C40.5659 70.2798 41.8471 71.5611 43.4276 71.5611Z" fill={color} />
        <path d="M63.3013 68.6994C63.3013 70.2793 62.0204 71.5611 60.4396 71.5611C58.8589 71.5611 57.7033 70.4003 57.5885 68.9256C57.5815 68.8511 57.5789 68.7757 57.5789 68.6994C57.5789 67.1186 58.8598 65.8378 60.4396 65.8378H60.4519C62.0274 65.8448 63.3013 67.123 63.3013 68.6994Z" fill={color} />
        <path d="M54.7953 68.6994C54.7953 69.7418 54.2377 70.6545 53.4039 71.156C52.9743 71.4129 52.4711 71.5611 51.9337 71.5611C50.3538 71.5611 49.072 70.2793 49.072 68.6994C49.072 67.1195 50.3538 65.8378 51.9337 65.8378C53.4188 65.8378 54.6393 66.9679 54.7813 68.4154C54.7909 68.5092 54.7953 68.6038 54.7953 68.6994Z" fill={color} />
        <g opacity="0.3">
          <path d="M3.35938 91.6581H1.07214C0.787793 91.6581 0.51503 91.5449 0.313965 91.3434C0.112899 91.1419 0 90.8685 0 90.5835C0 90.2985 0.112899 90.0252 0.313965 89.8237C0.51503 89.6221 0.787793 89.5089 1.07214 89.5089H3.35938C3.64373 89.5089 3.91649 89.6221 4.11756 89.8237C4.31862 90.0252 4.43152 90.2985 4.43152 90.5835C4.43152 90.8685 4.31862 91.1419 4.11756 91.3434C3.91649 91.5449 3.64373 91.6581 3.35938 91.6581Z" fill={color} />
          <path d="M12.2221 91.6581H9.64892C9.36457 91.6581 9.09181 91.5449 8.89075 91.3434C8.68968 91.1419 8.57678 90.8685 8.57678 90.5835C8.57678 90.2985 8.68968 90.0252 8.89075 89.8237C9.09181 89.6221 9.36457 89.5089 9.64892 89.5089H12.2221C12.5064 89.5089 12.7792 89.6221 12.9802 89.8237C13.1813 90.0252 13.2942 90.2985 13.2942 90.5835C13.2942 90.8685 13.1813 91.1419 12.9802 91.3434C12.7792 91.5449 12.5064 91.6581 12.2221 91.6581Z" fill={color} />
          <path d="M6.57568 97.3176C6.29133 97.3176 6.01857 97.2044 5.8175 97.0029C5.61644 96.8013 5.50354 96.528 5.50354 96.243V93.9505C5.50354 93.6655 5.61644 93.3921 5.8175 93.1906C6.01857 92.9891 6.29133 92.8759 6.57568 92.8759C6.86003 92.8759 7.13279 92.9891 7.33386 93.1906C7.53493 93.3921 7.64782 93.6655 7.64782 93.9505V96.243C7.64782 96.528 7.53493 96.8013 7.33386 97.0029C7.13279 97.2044 6.86003 97.3176 6.57568 97.3176Z" fill={color} />
          <path d="M6.57568 88.7209C6.29133 88.7209 6.01857 88.6077 5.8175 88.4061C5.61644 88.2046 5.50354 87.9313 5.50354 87.6463V85.0672C5.50354 84.7822 5.61644 84.5088 5.8175 84.3073C6.01857 84.1058 6.29133 83.9926 6.57568 83.9926C6.86003 83.9926 7.13279 84.1058 7.33386 84.3073C7.53493 84.5088 7.64782 84.7822 7.64782 85.0672V87.6463C7.64782 87.9313 7.53493 88.2046 7.33386 88.4061C7.13279 88.6077 6.86003 88.7209 6.57568 88.7209Z" fill={color} />
        </g>
        <g opacity="0.3">
          <path d="M102.359 87.6656H100.072C99.7878 87.6656 99.515 87.5524 99.314 87.3508C99.1129 87.1493 99 86.876 99 86.591C99 86.306 99.1129 86.0326 99.314 85.8311C99.515 85.6296 99.7878 85.5164 100.072 85.5164H102.359C102.644 85.5164 102.916 85.6296 103.118 85.8311C103.319 86.0326 103.432 86.306 103.432 86.591C103.432 86.876 103.319 87.1493 103.118 87.3508C102.916 87.5524 102.644 87.6656 102.359 87.6656Z" fill={color} />
          <path d="M111.222 87.6656H108.649C108.365 87.6656 108.092 87.5524 107.891 87.3508C107.69 87.1493 107.577 86.876 107.577 86.591C107.577 86.306 107.69 86.0326 107.891 85.8311C108.092 85.6296 108.365 85.5164 108.649 85.5164H111.222C111.507 85.5164 111.779 85.6296 111.98 85.8311C112.181 86.0326 112.294 86.306 112.294 86.591C112.294 86.876 112.181 87.1493 111.98 87.3508C111.779 87.5524 111.507 87.6656 111.222 87.6656Z" fill={color} />
          <path d="M105.576 93.3252C105.291 93.3252 105.018 93.212 104.817 93.0104C104.616 92.8089 104.503 92.5356 104.503 92.2506V89.958C104.503 89.673 104.616 89.3997 104.817 89.1982C105.018 88.9966 105.291 88.8834 105.576 88.8834C105.86 88.8834 106.133 88.9966 106.334 89.1982C106.535 89.3997 106.648 89.673 106.648 89.958V92.2506C106.648 92.5356 106.535 92.8089 106.334 93.0104C106.133 93.212 105.86 93.3252 105.576 93.3252Z" fill={color} />
          <path d="M105.576 84.7283C105.291 84.7283 105.018 84.6151 104.817 84.4136C104.616 84.212 104.503 83.9387 104.503 83.6537V81.0746C104.503 80.7896 104.616 80.5163 104.817 80.3148C105.018 80.1132 105.291 80 105.576 80C105.86 80 106.133 80.1132 106.334 80.3148C106.535 80.5163 106.648 80.7896 106.648 81.0746V83.6537C106.648 83.9387 106.535 84.212 106.334 84.4136C106.133 84.6151 105.86 84.7283 105.576 84.7283Z" fill={color} />
        </g>
        <path opacity="0.3" d="M19.2342 116.446C18.5468 116.446 17.875 116.242 17.3035 115.859C16.732 115.476 16.2866 114.932 16.0236 114.296C15.7606 113.659 15.6917 112.959 15.8258 112.283C15.9599 111.607 16.2909 110.987 16.7769 110.5C17.2629 110.013 17.8821 109.681 18.5563 109.546C19.2304 109.412 19.9292 109.481 20.5642 109.745C21.1992 110.008 21.7418 110.455 22.1237 111.028C22.5055 111.6 22.7094 112.274 22.7094 112.963C22.7087 113.886 22.3423 114.772 21.6907 115.425C21.0392 116.078 20.1556 116.445 19.2342 116.446ZM19.2342 111.629C18.9709 111.629 18.7137 111.707 18.4948 111.854C18.276 112 18.1053 112.208 18.0046 112.452C17.9038 112.696 17.8775 112.964 17.9289 113.223C17.9802 113.482 18.107 113.719 18.2931 113.906C18.4792 114.092 18.7163 114.22 18.9745 114.271C19.2327 114.322 19.5003 114.296 19.7434 114.195C19.9866 114.094 20.1946 113.923 20.3408 113.704C20.487 113.484 20.5651 113.227 20.5651 112.963C20.5653 112.787 20.531 112.614 20.4642 112.452C20.3974 112.29 20.2994 112.143 20.1758 112.019C20.0522 111.894 19.9054 111.796 19.7439 111.729C19.5823 111.662 19.4091 111.627 19.2342 111.627V111.629Z" fill={color} />
        <path opacity="0.3" d="M133.589 49.0509C132.901 49.0509 132.229 48.8466 131.658 48.4638C131.087 48.0811 130.641 47.5371 130.378 46.9006C130.115 46.2642 130.046 45.5638 130.18 44.8881C130.314 44.2125 130.645 43.5918 131.131 43.1047C131.617 42.6175 132.237 42.2858 132.911 42.1514C133.585 42.017 134.284 42.086 134.919 42.3496C135.554 42.6133 136.096 43.0597 136.478 43.6325C136.86 44.2053 137.064 44.8788 137.064 45.5677C137.063 46.4911 136.696 47.3764 136.045 48.0294C135.393 48.6824 134.51 49.0497 133.589 49.0509ZM133.589 44.2337C133.325 44.2337 133.068 44.312 132.849 44.4585C132.631 44.6051 132.46 44.8134 132.359 45.0572C132.258 45.3009 132.232 45.5692 132.283 45.8279C132.335 46.0867 132.462 46.3244 132.648 46.5109C132.834 46.6975 133.071 46.8245 133.329 46.876C133.587 46.9275 133.855 46.9011 134.098 46.8001C134.341 46.6991 134.549 46.5282 134.695 46.3088C134.842 46.0894 134.92 45.8315 134.92 45.5677C134.919 45.2139 134.779 44.8746 134.529 44.6243C134.28 44.374 133.942 44.233 133.589 44.2323V44.2337Z" fill={color} />
        <path opacity="0.3" d="M29.2342 43.8914C28.5469 43.8914 27.875 43.6872 27.3035 43.3044C26.732 42.9217 26.2865 42.3777 26.0235 41.7412C25.7605 41.1047 25.6917 40.4044 25.8258 39.7287C25.9599 39.053 26.2909 38.4324 26.7769 37.9453C27.2629 37.4581 27.8822 37.1264 28.5563 36.992C29.2304 36.8576 29.9291 36.9266 30.5641 37.1902C31.1991 37.4538 31.7418 37.9003 32.1237 38.4731C32.5055 39.0459 32.7093 39.7193 32.7093 40.4082C32.7082 41.3317 32.3417 42.217 31.6902 42.87C31.0387 43.523 30.1555 43.8903 29.2342 43.8914ZM29.2342 39.0743C28.971 39.0743 28.7137 39.1525 28.4949 39.2991C28.276 39.4457 28.1053 39.654 28.0046 39.8978C27.9039 40.1415 27.8776 40.4097 27.9289 40.6685C27.9803 40.9273 28.107 41.1649 28.2932 41.3515C28.4793 41.5381 28.7164 41.6651 28.9745 41.7166C29.2327 41.768 29.5003 41.7416 29.7435 41.6407C29.9867 41.5397 30.1946 41.3687 30.3408 41.1494C30.4871 40.93 30.565 40.6721 30.565 40.4082C30.5647 40.0544 30.4245 39.7152 30.175 39.4649C29.9255 39.2146 29.5872 39.0736 29.2342 39.0729V39.0743Z" fill={color} />
        <g opacity="0.3">
          <path d="M103.158 10.5796C105.381 11.8353 107.528 13.2189 109.591 14.7233C109.705 14.8064 109.834 14.8662 109.971 14.8993C110.108 14.9324 110.25 14.9381 110.389 14.9161C110.528 14.8942 110.662 14.845 110.782 14.7713C110.902 14.6977 111.007 14.601 111.089 14.4869C111.172 14.3728 111.232 14.2434 111.265 14.1062C111.298 13.969 111.304 13.8266 111.282 13.6872C111.26 13.5477 111.211 13.414 111.137 13.2936C111.064 13.1731 110.967 13.0684 110.853 12.9853C108.725 11.4323 106.508 10.0038 104.215 8.70691C104.092 8.63684 103.957 8.59177 103.817 8.57427C103.676 8.55678 103.534 8.56722 103.398 8.60499C103.262 8.64276 103.135 8.70711 103.023 8.79436C102.912 8.8816 102.819 8.99001 102.75 9.11337C102.681 9.23672 102.636 9.37259 102.619 9.51316C102.603 9.65373 102.614 9.79623 102.652 9.93248C102.691 10.0687 102.755 10.196 102.843 10.3071C102.931 10.4181 103.039 10.5108 103.163 10.5796H103.158Z" fill={color} />
          <path d="M86.8888 1.87986C74.9827 -0.977184 62.529 -0.571364 50.8333 3.05477C50.6957 3.09394 50.5674 3.1603 50.4558 3.24994C50.3442 3.33958 50.2517 3.45069 50.1835 3.57673C50.1154 3.70277 50.0732 3.84119 50.0593 3.98385C50.0454 4.1265 50.06 4.27051 50.1024 4.40739C50.1449 4.54427 50.2143 4.67126 50.3064 4.78088C50.3985 4.8905 50.5116 4.98054 50.6389 5.04569C50.7663 5.11084 50.9055 5.14979 51.0481 5.16025C51.1907 5.17071 51.3339 5.15246 51.4694 5.10658C62.7966 1.59507 74.8577 1.20212 86.3884 3.96892C86.665 4.03542 86.9566 3.98907 87.1991 3.84007C87.4416 3.69108 87.6151 3.45163 87.6814 3.17442C87.7478 2.8972 87.7015 2.60492 87.5529 2.36188C87.4042 2.11883 87.1654 1.94492 86.8888 1.87842V1.87986Z" fill={color} />
          <path d="M29.2664 15.7523C29.1768 15.6434 29.0666 15.5533 28.9421 15.4871C28.8177 15.4209 28.6814 15.38 28.5412 15.3667C28.401 15.3534 28.2595 15.368 28.1249 15.4095C27.9903 15.4511 27.8652 15.5189 27.7568 15.6091C26.6346 16.5404 25.5282 17.5219 24.4689 18.5263C24.3665 18.6232 24.2841 18.7394 24.2265 18.8682C24.1689 18.997 24.1372 19.1359 24.1331 19.277C24.1291 19.4181 24.1528 19.5586 24.2029 19.6905C24.2531 19.8224 24.3286 19.9432 24.4253 20.0458C24.522 20.1484 24.6379 20.231 24.7664 20.2887C24.8949 20.3465 25.0335 20.3783 25.1743 20.3824C25.3151 20.3864 25.4552 20.3626 25.5868 20.3124C25.7184 20.2621 25.8389 20.1864 25.9413 20.0895C26.9691 19.1166 28.0412 18.1652 29.1277 17.2625C29.3453 17.0804 29.4822 16.8192 29.5082 16.5361C29.5342 16.2531 29.4473 15.9713 29.2664 15.7523Z" fill={color} />
          <path d="M13.4513 34.0655C13.3288 33.9958 13.1939 33.951 13.0541 33.9337C12.9144 33.9163 12.7727 33.9268 12.637 33.9645C12.5013 34.0021 12.3742 34.0663 12.2633 34.1532C12.1523 34.2401 12.0596 34.3481 11.9904 34.471C7.64288 42.1374 4.84272 50.5863 3.74924 59.3362C3.02971 65.0583 3.02971 70.8482 3.74924 76.5702C3.78192 76.8296 3.90775 77.0681 4.10327 77.2411C4.29878 77.4141 4.55052 77.5098 4.81133 77.5102C4.85911 77.5104 4.90686 77.5075 4.95428 77.5016C5.09403 77.4839 5.22886 77.4387 5.35117 77.3687C5.47348 77.2987 5.5809 77.2052 5.66709 77.0935C5.75327 76.9818 5.81656 76.8542 5.85345 76.7179C5.89035 76.5817 5.90011 76.4395 5.88207 76.2994C5.18543 70.7567 5.18543 65.1483 5.88207 59.6056C6.94166 51.135 9.65288 42.956 13.8616 35.5341C13.9315 35.4109 13.9765 35.2749 13.9938 35.1342C14.011 34.9934 14.0002 34.8506 13.9621 34.714C13.9239 34.5775 13.8591 34.4498 13.7714 34.3385C13.6837 34.2272 13.5749 34.1344 13.4513 34.0655Z" fill={color} />
          <path d="M38.2081 124.937C35.6796 123.471 33.2507 121.84 30.9375 120.052C30.7123 119.878 30.4273 119.801 30.1453 119.837C29.8632 119.874 29.6074 120.021 29.4337 120.247C29.2601 120.473 29.183 120.758 29.2194 121.041C29.2559 121.324 29.4028 121.58 29.628 121.754C32.0167 123.6 34.5248 125.285 37.136 126.798C37.3824 126.94 37.6752 126.979 37.95 126.905C38.2248 126.831 38.459 126.651 38.6012 126.404C38.7434 126.157 38.7819 125.863 38.7082 125.588C38.6344 125.313 38.4545 125.078 38.2081 124.935V124.937Z" fill={color} />
          <path d="M85.0265 132.206C74.6833 134.455 63.9532 134.185 53.7357 131.418C53.5985 131.377 53.4544 131.363 53.3119 131.379C53.1694 131.394 53.0314 131.438 52.9061 131.507C52.7807 131.577 52.6706 131.671 52.5822 131.784C52.4938 131.897 52.4289 132.027 52.3913 132.166C52.3538 132.304 52.3444 132.449 52.3636 132.591C52.3828 132.734 52.4302 132.871 52.5032 132.994C52.5761 133.118 52.673 133.226 52.7882 133.311C52.9034 133.396 53.0345 133.458 53.1738 133.492C63.7229 136.349 74.802 136.628 85.481 134.306C85.6221 134.279 85.7564 134.225 85.8761 134.145C85.9958 134.066 86.0983 133.963 86.1776 133.843C86.257 133.724 86.3115 133.589 86.338 133.448C86.3645 133.306 86.3626 133.161 86.332 133.02C86.3015 132.88 86.2431 132.747 86.1603 132.629C86.0776 132.512 85.9722 132.412 85.8503 132.336C85.7284 132.26 85.5926 132.21 85.4509 132.188C85.3091 132.165 85.1643 132.172 85.0251 132.206H85.0265Z" fill={color} />
          <path d="M107.867 122.354C106.346 123.393 104.761 124.379 103.158 125.285C102.912 125.425 102.731 125.658 102.656 125.932C102.58 126.205 102.616 126.498 102.755 126.746C102.894 126.993 103.125 127.175 103.398 127.253C103.671 127.33 103.963 127.295 104.21 127.157C105.866 126.222 107.498 125.204 109.071 124.131C109.304 123.97 109.464 123.723 109.517 123.444C109.569 123.165 109.509 122.876 109.349 122.641C109.19 122.407 108.944 122.245 108.666 122.191C108.387 122.137 108.099 122.196 107.864 122.354H107.867Z" fill={color} />
          <path d="M136.072 82.6814C135.795 82.6144 135.504 82.6601 135.261 82.8084C135.018 82.9566 134.844 83.1954 134.776 83.4723C133.628 88.211 131.954 92.8061 129.786 97.1715C130.147 98.3311 130.33 99.5389 130.331 100.754C133.244 95.4775 135.44 89.8347 136.861 83.9752C136.927 83.6988 136.88 83.4074 136.733 83.165C136.585 82.9225 136.347 82.7486 136.072 82.6814Z" fill={color} />
          <path d="M120.477 113.834C120.623 113.834 120.767 113.804 120.901 113.746C121.034 113.688 121.155 113.603 121.255 113.497C120.983 113.617 120.707 113.729 120.43 113.832L120.477 113.834Z" fill={color} />
          <path d="M138.491 61.8046C138.348 60.1468 138.132 58.4775 137.864 56.8427C137.814 56.5651 137.658 56.3183 137.428 56.1555C137.198 55.9928 136.913 55.9272 136.636 55.9729C136.358 56.0186 136.109 56.172 135.943 56.3998C135.777 56.6277 135.708 56.9118 135.749 57.1908C136.008 58.767 136.212 60.3903 136.355 61.9965C136.531 63.9552 136.619 65.9526 136.619 67.9313C136.619 68.2163 136.732 68.4896 136.933 68.6912C137.134 68.8927 137.407 69.0059 137.691 69.0059C137.976 69.0059 138.248 68.8927 138.45 68.6912C138.651 68.4896 138.764 68.2163 138.764 67.9313C138.764 65.8895 138.672 63.8277 138.491 61.8046Z" fill={color} />
        </g>
        <path d="M51.6509 50.88V45.4836V40.3782C51.6509 35.9599 55.2326 32.3782 59.6509 32.3782H75.1636M51.6509 85.9564V93.76C51.6509 98.1783 55.2326 101.76 59.6509 101.76H82.1963C86.6146 101.76 90.1963 98.1783 90.1963 93.76V64.7564" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path d="M128.784 56.0732C128.784 57.8179 127.36 59.2424 125.612 59.2424L85.4997 60.0029C85.4996 60.003 85.4994 60.0031 85.4993 60.0032L79.3618 65.8721V30.8764C79.3618 29.1336 80.7839 27.7109 82.5354 27.7109H125.612C127.362 27.7109 128.784 29.1343 128.784 30.8764V56.0732Z" stroke={color} strokeWidth="3" />
        <path d="M95.203 44.5959C95.0015 44.4365 94.7715 44.2898 94.5133 44.1574C94.1794 43.9852 93.7353 43.8146 93.1763 43.644C92.6535 43.4845 92.0283 43.3251 91.3071 43.1688C90.7039 43.0429 90.3228 42.9041 90.1606 42.7574C89.9953 42.6155 89.9134 42.4545 89.9134 42.2775C89.9134 42.0335 90.0126 41.8262 90.2141 41.6556C90.4157 41.4866 90.7134 41.4005 91.1118 41.4005C91.5937 41.4005 91.9716 41.5137 92.2456 41.7433C92.5196 41.9713 92.6991 42.3381 92.7842 42.8419L93.1763 42.818L95.2015 42.6984L95.9998 42.6506C95.8975 41.7975 95.6298 41.1135 95.2015 40.6C95.0487 40.415 94.8739 40.254 94.6786 40.1137C94.2771 39.8267 93.7763 39.6146 93.1763 39.4838C92.6708 39.3722 92.0944 39.3164 91.4488 39.3164C90.2992 39.3164 89.3937 39.4631 88.7339 39.7549C88.0725 40.0467 87.578 40.4501 87.2489 40.9604C86.9197 41.4722 86.7559 42.0144 86.7559 42.5884C86.7559 43.4638 87.0772 44.183 87.7197 44.7474C88.3575 45.3119 89.4252 45.7647 90.9228 46.1044C91.8361 46.3085 92.4188 46.5237 92.6708 46.7534C92.9212 46.9814 93.0471 47.2413 93.0471 47.5315C93.0471 47.8361 92.9149 48.1055 92.6519 48.3367C92.3873 48.568 92.0125 48.6828 91.5259 48.6828C90.8724 48.6828 90.37 48.4563 90.0189 48.0035C89.8031 47.7228 89.6598 47.3162 89.589 46.7837L86.3418 46.9894C86.4379 48.1183 86.8473 49.0495 87.5701 49.7814C88.2929 50.5133 89.5952 50.88 91.4739 50.88C92.1054 50.88 92.6739 50.8258 93.1779 50.7158C93.5275 50.6409 93.8456 50.5388 94.1338 50.4112C94.5432 50.2295 94.8991 49.9967 95.203 49.716C95.4203 49.5135 95.6125 49.2871 95.7762 49.0336C96.1683 48.4292 96.3636 47.7675 96.3636 47.0499C96.3636 46.4392 96.2172 45.8875 95.9227 45.3948C95.7463 45.0982 95.507 44.8319 95.203 44.5943V44.5959Z" fill={color} />
        <path d="M104.081 46.1847L102.363 40.0873H97.9055V50.1091H100.682V42.4659L102.816 50.1091H105.328L107.465 42.4659V50.1091H110.24V40.0873H105.788L104.081 46.1847Z" fill={color} />
        <path d="M119.953 44.1574C119.308 43.8242 118.238 43.4941 116.747 43.1688C116.144 43.0429 115.763 42.9041 115.601 42.7574C115.435 42.6155 115.352 42.4545 115.352 42.2775C115.352 42.0335 115.453 41.8262 115.654 41.6556C115.856 41.4866 116.153 41.4005 116.55 41.4005C117.034 41.4005 117.412 41.5137 117.684 41.7433C117.958 41.9713 118.138 42.3381 118.223 42.8419L121.44 42.6506C121.3 41.4914 120.859 40.6447 120.119 40.1137C119.377 39.5827 118.301 39.3164 116.89 39.3164C115.741 39.3164 114.835 39.4631 114.174 39.7549C113.514 40.0467 113.018 40.4501 112.689 40.9604C112.36 41.4722 112.196 42.0144 112.196 42.5884C112.196 43.4638 112.517 44.183 113.161 44.7474C113.799 45.3119 114.867 45.7647 116.363 46.1044C117.278 46.3085 117.859 46.5237 118.111 46.7534C118.361 46.9814 118.487 47.2413 118.487 47.5315C118.487 47.8361 118.355 48.1055 118.092 48.3367C117.827 48.568 117.453 48.6828 116.964 48.6828C116.312 48.6828 115.809 48.4563 115.459 48.0035C115.243 47.7228 115.1 47.3162 115.029 46.7837L111.782 46.9894C111.878 48.1183 112.287 49.0495 113.01 49.7814C113.733 50.5133 115.035 50.88 116.914 50.88C117.983 50.88 118.87 50.7238 119.574 50.4112C120.278 50.0987 120.824 49.6379 121.216 49.0336C121.608 48.4292 121.804 47.7675 121.804 47.0499C121.804 46.4392 121.657 45.8875 121.363 45.3948C121.07 44.9021 120.601 44.4891 119.955 44.1558L119.953 44.1574Z" fill={color} />
      </svg>
    </>
  );
}