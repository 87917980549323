import { Modal } from "antd";
import { ArrowIcon } from "../../SvgComponents/ArrowIcon/ArrowIcon";
import { Col, Row } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import Helper from "../../../util/helper";
import cancel from "./../../../assets/icons/cancel.svg";

import SignaturePad from "react-signature-canvas";
import gzip from "gzip-js";
import { AdditionalSignaturesContext } from "../../../contexts/AdditionalSignaturesContext";
import { useTranslation } from "react-i18next";
import { QRLoader } from "../../QRLoader/QRLoader";
import { isMobile, isDesktop } from 'react-device-detect';
import { useOrientation } from "../../../hooks/useOrientation";
import { RGPDCheckbox } from "../../RGPDCheckbox/RGPDCheckbox";
import { OneShotCheckbox } from "../../OneShotCheckbox/OneShotCheckbox";
import OneShotService from "../../../services/OneShotService";

import './AdditionalSignatureModal.css';
import { ShowErrorModal } from "../ShowErrorModal/ShowErrorModal";

export const AdditionalSignatureModal = ({ openSignatureModal, setOpenSignatureModal, boxData }) => {
    const { isPortrait } = useOrientation();
    const { setSignatureData, addCompletedBox, signerDTO  } = useContext(AdditionalSignaturesContext);

    const sigPad = useRef({});
    const { t } = useTranslation();

    const [receivedGraphData, setReceivedGraphData] = useState({});
    const { SignatureImage, SignatureRawData } = receivedGraphData;

    const containerRef = useRef();

    const [disable, setDisable] = useState(true);
    const [inputRgpdValue, setInputRgpdValue] = useState(false);
    const [inputOneShot, setInputOneShot] = useState(false);
    const [requestId, setRequestId] = useState(false);

    const [openShowErrorModal, setOpenShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isOneShot = signerDTO.OneShot;
    const signerGuid = signerDTO.SignerGUI;

    const [canvasWidth, setCanvasWidth] = useState(null);
    const [canvasHeight, setCanvasHeight] = useState(null);

    const isDarkMode = () => {
        return window.matchMedia && 
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      };
    
    const getPenColor = () => {
        if (isDarkMode()) {
            return 'rgb(255, 255, 255)';
        }
        return 'rgb(0, 0, 255)';
    }

    useEffect(() => {
        if (SignatureImage && SignatureRawData) checkDisableFinishButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedGraphData]);

    useEffect(() => {
        checkDisableFinishButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRgpdValue, inputOneShot]);


    const handleDraw = () => {
        checkDisableFinishButton();
        const canvas = sigPad.current.getCanvas();
        if (canvas) {
          canvas.classList.remove('canvas-with-image');
        }
    };

    const handleCancel = () => {
        clear();
        setOpenSignatureModal(false);
    }

    const handleContinue = async () => {
        let points;
        let image;
        let signatureRawDataZippedAndEncoded = "";

        if (sigPad?.current && typeof sigPad?.current?.toDataURL === 'function') {
            const canvas = sigPad.current.getCanvas();
            const ctx = canvas.getContext("2d");

            if (isDarkMode) {
                // 1️⃣ Guardar el estado original
                const originalImage = canvas.toDataURL(); // Guarda la imagen original
            
                // 2️⃣ Crear un canvas temporal
                const tempCanvas = document.createElement("canvas");
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;
                const tempCtx = tempCanvas.getContext("2d");
            
                // 3️⃣ Copiar la firma y cambiar su color a azul
                tempCtx.drawImage(canvas, 0, 0);
                tempCtx.globalCompositeOperation = "source-atop";
                tempCtx.fillStyle = "blue";
                tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
            
                // 4️⃣ Aplicar fondo blanco
                tempCtx.globalCompositeOperation = "destination-over";
                tempCtx.fillStyle = "white";
                tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
            
                // 5️⃣ Capturar la imagen con los nuevos colores
                image = tempCanvas.toDataURL();
            
                // 6️⃣ Restaurar la firma original en el canvas principal
                const img = new Image();
                img.src = originalImage;
                img.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
                };
            } else {
                // 🔹 Light Mode: Captura normal sin cambios
                image = canvas.toDataURL();
            }

            points = Helper.computePressureForSignaturePoints(sigPad.current.toData());
            const timestampStart = points[0][0].time;
    
            const signatureRawDataStr = points.reduce((str, element) => {
            const stroke = element.reduce((str, rd, i) => {
                const time = rd.time - timestampStart;
                const { x, y, pressure } = rd;
                const letter = i === 0 ? "U" : "T";
                return `${str}${time}:${x}:${y}:${pressure}:${letter};`;
            }, "");
            return `${str}${stroke}`;
            }, "");
    
            signatureRawDataZippedAndEncoded = Helper.base64ArrayBuffer(
                gzip.zip(signatureRawDataStr)
            );
        }

        let signatureImageRequest = image?.substr(22);

        const signatureDataFromCanvas = {
            SignatureImage: SignatureImage ? SignatureImage : signatureImageRequest,
            SignatureRawData: SignatureRawData ? SignatureRawData : signatureRawDataZippedAndEncoded,
        };
        setSignatureData(signatureDataFromCanvas);

        // save to localStorage for SII, AOCValid and Criipto callbacks
        localStorage.setItem("additionalSignaturesSignatureData", JSON.stringify(signatureDataFromCanvas));

        addCompletedBox(boxData);

        sessionStorage.setItem("COMPLETED_ADDITIONAL_SIGNATURE", true);
        setOpenSignatureModal(false);
    }

    const oneShotFlow = () => {
        OneShotService.createRequest(signerDTO.SignerGUI)
          .then(res => {
            const requestId = res.data.data.RequestId;
            OneShotService.sendOTP(signerDTO.SignerGUI, requestId)
              .then(() => {
                setRequestId(requestId);
              })
              .catch(error => {
                setOpenShowErrorModal(true);
                const errorMessage = t('document_detail.one_shot.send_otp_error_message');
                setErrorMessage(errorMessage);
              });
          })
          .catch(err => {
            setOpenShowErrorModal(true);
            const errorMessage = t('document_detail.one_shot.create_request_error_message');
            setErrorMessage(errorMessage);
          });
    };

    const clear = () => {
        setDisable(true);
        if (receivedGraphData.SignatureImage) {
          setReceivedGraphData({});
          return;
        }
        sigPad.current.clear();
    
        const canvas = sigPad.current.getCanvas();
        if (canvas) {
          canvas.classList.add('canvas-with-image');
        }
      };

    useEffect(() => {
        sessionStorage.removeItem("COMPLETED_ADDITIONAL_SIGNATURE");

        if (isOneShot) oneShotFlow();
    }, []);

    const checkDisableFinishButton = () => {
        let emptySignature = !SignatureImage && !SignatureRawData;
        if (sigPad?.current && typeof sigPad?.current?.toData === 'function') {
            emptySignature = sigPad?.current?.toData()?.length === 0;
        }
        const disableButton = (isOneShot && !inputOneShot) || (signerDTO.RGPDField && !inputRgpdValue) || emptySignature;
        setDisable(disableButton);
      };

    const getWidthAndHeightContainers = () => {
        let width = containerRef.current.clientWidth;
        width = isMobile ? width - 24 : width - 24;
    
        const canvasHeight = isDesktop ? 300 : isPortrait ? 418 : 200;
    
        setCanvasWidth(width);
        setCanvasHeight(canvasHeight);
      };

    return (
        <>
            <Modal
                style={{ top: 0 }}
                width={'95%'}
                footer={null}
                open={openSignatureModal}
                closable={true}
            >
                <div className="modal-container additional-signature-modal-container">
                    <Row>
                        {
                            !isMobile &&
                            <Col md={12} lg={12} xs={12} className="document-preview-title document-sign-title">
                                <div className="document-sign-header-text">
                                    <span className="signer-name"><strong> {t('sign.draw_signature')} </strong> </span>
                                    <span className="subtitle"> {t('sign.scan_qr')} </span>
                                </div>
                            </Col>
                        }
                    </Row>
                    <hr />
                    <Row>
                        <Col md={7} lg={8} ref={containerRef}>
                            {
                                SignatureImage ? <img src={`data:image/png;base64,${SignatureImage}`} className="graph-image" alt="QR Code" /> :
                                    <SignaturePad
                                        id="sp"
                                        penColor={getPenColor()}
                                        canvasProps={{ width: canvasWidth, height: canvasHeight, className: 'signatureCanvas' }}
                                        ref={sigPad}
                                        onBegin={() => handleDraw()}
                                        onEnd={() => handleDraw()}
                                    />
                            }
                            <RGPDCheckbox setInputRgpdValue={setInputRgpdValue} signerGuid={signerGuid} signerDTO={signerDTO} getWidthAndHeightContainers={getWidthAndHeightContainers}/>
                            <OneShotCheckbox oneShot={isOneShot} setInputOneShot={setInputOneShot} signerGuid={signerGuid} requestId={requestId} />
                        </Col >
                        {
                            isDesktop &&
                                <Col md={4} lg={4} style={{marginTop: "0%", padding: "unset"}}>
                                    <QRLoader additionalSignature signerGuid={signerGuid} setReceivedGraphData={setReceivedGraphData} ></QRLoader>
                                </Col>
                        }
                    </Row >
                    <Row className="action-buttons mobile-button document-preview-buttons">
                        <Col md={6} xs={6}>
                            <button
                                type="button"
                                className="btn btn-secondary btn-cancel"
                                onClick={handleCancel}
                            >
                                <span className="document-preview-text">{t("general.cancel")}</span>
                                <img className="document-icon-image" src={cancel} alt="Check" />
                            </button>
                        </Col>
                        <Col md={6} xs={6}>
                            <button
                                disabled={disable}
                                type="button"
                                className="btn btn-primary btn-active"
                                onClick={handleContinue}
                            >
                                <span className="document-preview-text">{t("general.continue")}</span>
                                <ArrowIcon disabled={disable}/>
                            </button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <ShowErrorModal openShowErrorModal={openShowErrorModal} setOpenShowErrorModal={setOpenShowErrorModal} errorMessage={errorMessage} />
        </>
    );
}