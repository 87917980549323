import React, { useContext, useEffect, useRef, useState } from 'react';
import "./DocumentSign.css";
import { Col, Container, Row } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEvidenceData } from '../../util/getEvidenceData';
import Helper from '../../util/helper';
import gzip from 'gzip-js';
import EmailSignatureService from '../../services/EmailSignatureService';
import { useOrientation } from '../../hooks/useOrientation';
import { isMobile, isDesktop } from 'react-device-detect';
import { BatchContext } from '../../contexts/BatchContext';
import { Back } from '../../components/Back/Back';
import { AllowRotationModal } from '../../components/Modals/AllowRotationModal/AllowRotationModal';
import PendingDocumentService from '../../services/PendingDocumentService';
import { QRLoader } from '../../components/QRLoader/QRLoader';
import { RGPDCheckbox } from '../../components/RGPDCheckbox/RGPDCheckbox';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { DOCGUI, FIRST_LOAD, IS_SSI_AUTH_BATCH } from '../../constants/GeneralConstants';
import { ConfirmationModalSignature } from '../../components/Modals/ConfirmationModalSignature/ConfirmationModalSignature';
import { OneShotCheckbox } from '../../components/OneShotCheckbox/OneShotCheckbox';
import OneShotService from '../../services/OneShotService';
import { ShowErrorModal } from '../../components/Modals/ShowErrorModal/ShowErrorModal';
import { CancelIcon } from '../../components/SvgComponents/CancelIcon/CancelIcon';

export const DocumentSign = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [inputRgpdValue, setInputRgpdValue] = useState(false);
  const [sendingSignPetition, setSendingSignPetition] = useState(false);
  const [allowRotationModal, setAllowRotationModal] = useState(false);
  const [showConfirmationModalSignature, setShowConfirmationModalSignature] = useState(false);
  const [inputOneShot, setInputOneShot] = useState(false);
  const [loadingOneShotSign, setLoadingOneShotSign] = useState(false);
  const [openShowErrorModal, setOpenShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t('sign.one_shot.sign_one_shot_error_message'));
  const [errorOneShotFlow, setErrorOneShotFlow] = useState(false);

  const { isLandscape, isPortrait } = useOrientation();
  const signerDTO = location.state.documents.SignerDTO;
  const requestId = location.state.requestId;
  var signerGuid = signerDTO.SignerGUI;
  const allowRotation = signerDTO.AllowRotation;
  const emailId = location.state.emailId;
  const email = signerDTO.eMail;
  var sigPad = useRef({});
  const containerRef = useRef();
  const headerRef = useRef();
  const rgpdRef = useRef();
  const oneShotRef = useRef();
  const buttonsRef = useRef();
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);
  const [receivedGraphData, setReceivedGraphData] = useState({});
  const { SignatureImage, SignatureRawData } = receivedGraphData;

  const { remainingBatchDocuments, removeDocumentsFromBatch, batchOTP, selectedBatchDocumentGuids, selectedBatchDocuments } = useContext(BatchContext);

  const otpCode = remainingBatchDocuments > 0 ? batchOTP : location.state.otpCode;

  const ssiBatch = location.state.ssiBatch;
  const docGuiSsi = localStorage.getItem(DOCGUI);
  const docGuid = ssiBatch ? docGuiSsi : location.state.documents.DocGUI;
  const isOneShot = location.state.documents.SignerDTO.OneShot;
  const isBatchMultisignature = selectedBatchDocumentGuids.length > 0;
  const signerGuidSSI = location.state.signerGuid;

  useEffect(() => {
    if (SignatureImage && SignatureRawData) checkDisableFinishButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedGraphData]);

  // On Init
  useEffect(() => {
    clear();
    getWidthAndHeightContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkDisableFinishButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRgpdValue, inputOneShot])

  const isDarkMode = () => {
    return window.matchMedia && 
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const getPenColor = () => {
    if (isDarkMode()) {
      return 'rgb(255, 255, 255)';
    }
    return 'rgb(0, 0, 255)';
  }

  const getWidthAndHeightContainers = () => {
    if (!showConfirmationModalSignature) {
      let width = containerRef.current.clientWidth;
      width = isMobile ? width - 24 : width;
      const height = window.screen.height;
      const headerHeight = headerRef.current.clientHeight;
      const rgpdHeight = rgpdRef?.current?.clientHeight || 20;
      const oneShotHeight = oneShotRef?.current?.clientHeight || 20;
      const buttonHeight = buttonsRef?.current?.clientHeight || 55;
      const additionalHeight = isMobile ? 250 : 350;
      const totalHeight = height - headerHeight - rgpdHeight - oneShotHeight - buttonHeight - additionalHeight;

      const canvasHeight = isDesktop ? 300 : isPortrait ? 418 : 160;

      setCanvasWidth(width);
      setCanvasHeight(canvasHeight);
    }
  };

  useEffect(() => {
    getWidthAndHeightContainers();
    if (isMobile && isPortrait && allowRotation) {
      setAllowRotationModal(true);
    }
    if (isMobile && isLandscape) {
      setAllowRotationModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape, isPortrait]);


  const clear = () => {
    setDisable(true);
    if (receivedGraphData.SignatureImage) {
      setReceivedGraphData({});
      return;
    }
    sigPad.current.clear();
    const canvas = sigPad.current.getCanvas();
    if (canvas) {
      canvas.classList.add('canvas-with-image');
    }
  };

  const handleSignature = async () => {
    setSendingSignPetition(true);
    let signatureRawDataZippedAndEncoded = "";
    let signatureImageRequest;

    const evidenceData = await getEvidenceData();

    if (!SignatureImage && !SignatureRawData) {
      let points;
      let image;

      const canvas = sigPad.current.getCanvas();
      const ctx = canvas.getContext("2d");

      if (isDarkMode) {
        // 1️⃣ Guardar el estado original
        const originalImage = canvas.toDataURL(); // Guarda la imagen original
    
        // 2️⃣ Crear un canvas temporal
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const tempCtx = tempCanvas.getContext("2d");
    
        // 3️⃣ Copiar la firma y cambiar su color a azul
        tempCtx.drawImage(canvas, 0, 0);
        tempCtx.globalCompositeOperation = "source-atop";
        tempCtx.fillStyle = "blue";
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    
        // 4️⃣ Aplicar fondo blanco
        tempCtx.globalCompositeOperation = "destination-over";
        tempCtx.fillStyle = "white";
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    
        // 5️⃣ Capturar la imagen con los nuevos colores
        image = tempCanvas.toDataURL();
    
        // 6️⃣ Restaurar la firma original en el canvas principal
        const img = new Image();
        img.src = originalImage;
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
        };
      } else {
        // 🔹 Light Mode: Captura normal sin cambios
        image = canvas.toDataURL();
      }
       // 🔹 Generar los datos de la firma
      points = Helper.computePressureForSignaturePoints(sigPad.current.toData());
      const timestampStart = points[0][0].time;

      const signatureRawDataStr = points.reduce((str, element) => {
        const stroke = element.reduce((str, rd, i) => {
          const time = rd.time - timestampStart;
          const { x, y, pressure } = rd;
          const letter = i === 0 ? "U" : "T";
          return `${str}${time}:${x}:${y}:${pressure}:${letter};`;
        }, "");
        return `${str}${stroke}`;
      }, "");

      signatureRawDataZippedAndEncoded = Helper.base64ArrayBuffer(
        gzip.zip(signatureRawDataStr)
      );

      signatureImageRequest = image.substr(22);
    }

    const { SkipSMS, SkipSigDraw } = location.state.documents.SignerDTO;

    const signatureImage = SignatureImage ? SignatureImage : signatureImageRequest;
    const signatureData = SignatureRawData ? SignatureRawData : signatureRawDataZippedAndEncoded;

    const signBody = {
      SignatureImage: SkipSigDraw ? '' : signatureImage,
      SignatureRawData: SkipSigDraw ? '' : signatureData,
      OTP: (SkipSMS && !isOneShot) ? '' : otpCode,
      ...evidenceData,
    };
    let body, request;

    body = signBody;
    if (isOneShot) {
      setLoadingOneShotSign(true);
      OneShotService.sign(signerGuid, requestId, body)
        .then(res => {
          setLoadingOneShotSign(false);
          if (res) {
            PendingDocumentService.getPendingDocumentsList(emailId)
              .then(res => {
                const pendingDocuments = res.data;
                setSendingSignPetition(false);
                navigate("/signature-success", {
                  state: {
                    pendingDocuments,
                    emailId,
                    email
                  }
                });
              })
              .catch(err => {
                setSendingSignPetition(false);
                setShowConfirmationModalSignature(false);
                navigate("/signature-success", {
                  state: {
                    pendingDocuments: [],
                    emailId,
                    email
                  }
                });
              });
            return;
          }
          setOpenShowErrorModal(true);
          setSendingSignPetition(false);
          setShowConfirmationModalSignature(false);
        })
        .catch(err => {
          setShowConfirmationModalSignature(false);
          const error = err.response.data.toLowerCase();
          if (error.includes('code invalid')) {
            setErrorOneShotFlow(true);
            setErrorMessage(t('sign.one_shot.invalid_otp'));
          }
          setLoadingOneShotSign(false);
          setSendingSignPetition(false);
          setOpenShowErrorModal(true);
        });

      return;
    }

    // Check if is batch multisignature
    if (isBatchMultisignature) {
      const signersList = selectedBatchDocuments.map((document) => document.SignerDTO.SignerGUI);
      body = {
        SignatureGUIDs: signersList,
        EmailSignature: signBody,
      };
      request = EmailSignatureService.signMultipleDocuments(body);
    }

    if (!request) {
      const signer = ssiBatch ? signerGuidSSI : signerGuid;
      request = EmailSignatureService.sendSign(signer, body);
    }

    request
      .then(response => {
        localStorage.setItem(FIRST_LOAD, "true");

        removeDocumentsFromBatch(isBatchMultisignature ? selectedBatchDocumentGuids : [docGuid]);
        const pendingBatchDocsLength = remainingBatchDocuments - (selectedBatchDocumentGuids.length ? selectedBatchDocumentGuids.length : 1);

        if (pendingBatchDocsLength > 0 || ssiBatch) {
          setSendingSignPetition(false);
          localStorage.removeItem(DOCGUI);
          navigate('/batch-success', {
            state: {
              emailId,
            }
          })
          return;
        }
        PendingDocumentService.getPendingDocumentsList(emailId)
          .then(res => {
            const pendingDocuments = res.data;
            localStorage.removeItem(IS_SSI_AUTH_BATCH);
            setSendingSignPetition(false);
            navigate("/signature-success", {
              state: {
                pendingDocuments,
                emailId,
                email
              }
            });
          })
          .catch(err => {
            setSendingSignPetition(false);
            setShowConfirmationModalSignature(false);
            localStorage.removeItem(IS_SSI_AUTH_BATCH);
            navigate("/signature-success", {
              state: {
                pendingDocuments: [],
                emailId,
                email
              }
            });
          });
      })
      .catch(err => {
        if (err.response?.status === 409) {
          setOpenShowErrorModal(true);
          setErrorMessage(t('sign.error_batch_document'));
        } else {
          setOpenShowErrorModal(true);
          setErrorMessage(t('sign.one_shot.sign_one_shot_error_message'));
        }
        setSendingSignPetition(false);
        setShowConfirmationModalSignature(false);
      });
  }

  const handleBack = () => {
    if (isBatchMultisignature) {
      navigate('/documents', {
        state: {
          documents: location.state.documents,
          emailId
        }
      });

      return;
    }

    let link = "/document";
    navigate(link, {
      state: {
        documents: location.state.documents,
        emailId: location.state.emailId,
        otpCode: location.state.otpCode,
        attachedImages: location.state.attachedImages
      }
    });
  };

  const handleDraw = () => {
    checkDisableFinishButton();

    const canvas = sigPad.current.getCanvas();
    if (canvas) {
      canvas.classList.remove('canvas-with-image');
    }
  }

  const checkDisableFinishButton = () => {
    let emptySignature = !SignatureImage && !SignatureRawData;
    if (sigPad.current) {
      emptySignature = sigPad.current.toData().length === 0;
    }
    const disableButton = (isOneShot && !inputOneShot) || (signerDTO.RGPDField && !inputRgpdValue) || emptySignature;
    const result = disableButton;
    setDisable(result);
  };

  return (
    <>
      <Container className="document-preview-container sign-document-preview">
        <Row ref={headerRef}>
          {
            !isMobile &&
            <>
              <Col md={12} lg={12} xs={12} className="document-preview-title document-sign-title">
                <Back handleBack={handleBack} />
                <div className="document-sign-header-text">
                  <span className="signer-name"><strong> {t('sign.draw_signature')} </strong> </span>
                  <span className="subtitle"> {t('sign.scan_qr')} </span>
                </div>
              </Col>
            </>
          }
          {isMobile &&
            <>
              <Col md={5} sm={5} className="document-preview-title title-sign">
                <Back handleBack={handleBack} />
                <span className="signer-name"><strong> {t('sign.draw_signature')} </strong> </span>
              </Col>
              {isLandscape &&
                <>
                  <Col md={7} sm={7} className='landscape-buttons-container'>
                    <button type="button" className="btn btn-secondary btn-cancel button-canvas button-erase-signature" onClick={() => clear()}>
                      <span className="cancel-text">{t('sign.erase')}</span>
                      <CancelIcon />
                    </button>
                    <button type="button" className="btn btn-primary btn-active button-canvas" disabled={disable || sendingSignPetition} onClick={() => setShowConfirmationModalSignature(true)}>
                      <span className="cancel-text">{t('general.finish')}</span>
                      <ArrowIcon disabled={disable || sendingSignPetition} />
                    </button>
                  </Col>
                </>
              }
            </>
          }
        </Row>
        {
          !isMobile &&
          <hr />
        }
        <Row>
          <Col md={12} lg={8} ref={containerRef}>
            {
              SignatureImage ? <img src={`data:image/png;base64,${SignatureImage}`} className="graph-image" alt="QR Code" /> :
                <SignaturePad
                  id="sp"
                  penColor={getPenColor()}
                  canvasProps={{ width: canvasWidth, height: canvasHeight, className: 'signatureCanvas' }}
                  ref={sigPad}
                  onBegin={() => handleDraw()}
                  onEnd={() => handleDraw()}
                />
            }
            <RGPDCheckbox rgpdRef={rgpdRef} setInputRgpdValue={setInputRgpdValue} signerGuid={signerGuid} signerDTO={signerDTO} getWidthAndHeightContainers={getWidthAndHeightContainers}></RGPDCheckbox>
            <OneShotCheckbox oneShotRef={oneShotRef} oneShot={isOneShot} setInputOneShot={setInputOneShot} signerGuid={signerGuid} requestId={requestId} />
            {
              (isPortrait || (!isMobile && isLandscape)) &&
              <div className='document-sign-portrait-buttons action-buttons' ref={buttonsRef}>
                <button type="button" className="btn btn-secondary btn-cancel button-canvas button-erase-signature" onClick={() => clear()}>
                  <span className="cancel-text">{t('sign.erase')}</span>
                  <CancelIcon />
                </button>
                <button type="button" className="btn btn-primary btn-active button-canvas" disabled={disable || sendingSignPetition} onClick={() => setShowConfirmationModalSignature(true)}>
                  <span className="cancel-text">{t('general.finish')}</span>
                  <ArrowIcon disabled={disable || sendingSignPetition} />
                </button>
              </div>
            }
          </Col >
          {isDesktop &&
            <Col md={4} lg={4}>
              <QRLoader signerGuid={signerGuid} setReceivedGraphData={setReceivedGraphData} ></QRLoader>
            </Col>}
        </Row >
      </Container >
      <ShowErrorModal openShowErrorModal={openShowErrorModal} setOpenShowErrorModal={setOpenShowErrorModal} errorMessage={errorMessage} isBatchMultisignature={isBatchMultisignature} errorOneShotFlow={errorOneShotFlow} />
      <AllowRotationModal source="sign" allowRotationModal={allowRotationModal} />
      <ConfirmationModalSignature showConfirmationModalSignature={showConfirmationModalSignature} setShowConfirmationModalSignature={setShowConfirmationModalSignature} handleSignature={handleSignature} sendingSignPetition={sendingSignPetition} />
    </>
  );
};